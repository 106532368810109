import { CvForm } from "@/types";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import {
  CalendarIcon,
  ChevronLeft,
  ChevronRight,
  Plus,
  Trash,
} from "lucide-react";
import useQuery from "@/hooks/use-query";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@radix-ui/react-popover";
import { format } from "date-fns";
import { cn } from "@/lib/utils";
import { MonthPicker } from "../ui/month-picker";
type Props = {
  setTab: React.Dispatch<React.SetStateAction<string>>;
  cv: CvForm;
  setCv: React.Dispatch<React.SetStateAction<CvForm>>;
  errors: {
    [key: string]: string;
  };
};

const FormEducation = ({ setTab, cv, setCv, errors }: Props) => {
  const { data: statuses, loading: statusesLoading } =
    useQuery(`school-statuses`);
  const setArrayData = (index: number, key: string, value: string) => {
    if (key == "from" || key == "to") {
      const schoolsArray = cv.schools.map((school, i) => {
        if (i == index) {
          const date = new Date(value);
          const month =
            date.getMonth() >= 9
              ? date.getMonth() + 1
              : `0${date.getMonth() + 1}`;
          const year = date.getFullYear();
          const dateString = `${year}-${month}`;
          console.log(dateString);
          school[key] = dateString;
          console.log(school);
        }
        return school;
      });
      setCv({ ...cv, schools: schoolsArray });
    } else {
      const schoolsArray = cv.schools.map((school, i) => {
        if (i == index) {
          school[key] = value;
        }
        return school;
      });
      setCv({ ...cv, schools: schoolsArray });
    }
  };
  const addMoreSchool = () => {
    const obj = {
      name: "",
      major: "",
      from: new Date().toString(),
      to: new Date().toString(),
      school_status_id: 1,
    };
    setCv({ ...cv, schools: [...cv.schools, obj] });
  };

  const removeschool = (index: number) => {
    const schoolsArray = cv.schools.filter((_, i) => i !== index);
    setCv({ ...cv, schools: schoolsArray });
  };

  return (
    <div>
      <h1 className="text-xl">Education</h1>
      <div className="grid grid-cols-1 gap-4 mt-4 md:grid-cols-2">
        {errors["schools.0.name"] ? (
          <p className="text-destructive">• {errors["schools.0.name"]}</p>
        ) : null}
        {errors["schools.0.major"] ? (
          <p className="text-destructive">• {errors["schools.0.major"]}</p>
        ) : null}
        {errors["schools.0.from"] ? (
          <p className="text-destructive">• {errors["schools.0.from"]}</p>
        ) : null}
        {errors["schools.0.to"] ? (
          <p className="text-destructive">• {errors["schools.0.to"]}</p>
        ) : null}
      </div>
      {cv.schools?.map((school, i) => (
        <div
          key={i}
          className="grid grid-cols-1 gap-4 p-2 mt-4 border rounded-lg dark:border-slate-500 md:grid-cols-2"
        >
          <div className="flex items-center justify-between md:col-span-2">
            <h2 className="text-lg">• Education {i + 1}</h2>
            <Button
              onClick={() => removeschool(i)}
              size="icon"
              variant="secondary"
            >
              <Trash />
            </Button>
          </div>
          <div>
            <Label>School Name</Label>
            <Input
              value={cv.schools[i]?.name}
              onChange={(e) => setArrayData(i, "name", e.target.value)}
              className="mt-1"
              placeholder="school name"
            />
          </div>
          <div>
            <Label>Major</Label>
            <Input
              value={cv.schools[i]?.major}
              onChange={(e) => setArrayData(i, "major", e.target.value)}
              className="mt-1"
              placeholder="major"
            />
          </div>
          <div>
            <Label>Start Date</Label>
            {/* <input
              type="date"
              value={cv.schools[i]?.from}
              onChange={(e) => setArrayData(i, "from", e.target.value)}
              className="w-full p-2.5 mt-1 rounded-md outline-none bg-slate-100 dark:bg-slate-300 dark:text-slate-600 border-o ring-0"
            /> */}
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={"outline"}
                  className={cn(
                    "w-full border-0 mt-1 justify-start bg-slate-100 dark:bg-slate-300 dark:text-slate-700  text-left font-normal",
                    !cv.schools[i]?.from && "text-muted-foreground",
                  )}
                >
                  <CalendarIcon className="w-4 h-4 mr-2" />
                  {cv.schools[i]?.from ? (
                    format(cv.schools[i]?.from, "MMM yyyy")
                  ) : (
                    <span>Pick a month</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-full p-0 bg-white dark:bg-slate-700 dark:text-slate-300">
                <MonthPicker
                  onMonthSelect={(e: Date) =>
                    setArrayData(i, "from", e.toString())
                  }
                  selectedMonth={new Date(cv.schools[i]?.from)}
                />
              </PopoverContent>
            </Popover>
          </div>
          <div>
            <Label>End Date</Label>
            {/* <input
              type="date"
              value={cv.schools[i]?.to}
              onChange={(e) => setArrayData(i, "to", e.target.value)}
              className="w-full p-2.5 mt-1 rounded-md outline-none bg-slate-100 dark:bg-slate-300 dark:text-slate-600 border-o ring-0"
            /> */}
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={"outline"}
                  className={cn(
                    "w-full border-0 mt-1 justify-start bg-slate-100 dark:bg-slate-300 dark:text-slate-700  text-left font-normal",
                    !cv.schools[i]?.to && "text-muted-foreground",
                  )}
                >
                  <CalendarIcon className="w-4 h-4 mr-2" />
                  {cv.schools[i]?.to ? (
                    format(cv.schools[i]?.to, "MMM yyyy")
                  ) : (
                    <span>Pick a month</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-full p-0 bg-white dark:bg-slate-700 dark:text-slate-300">
                <MonthPicker
                  onMonthSelect={(e: Date) =>
                    setArrayData(i, "to", e.toString())
                  }
                  selectedMonth={new Date(cv.schools[i]?.to)}
                />
              </PopoverContent>
            </Popover>
          </div>
          <div className="mb-4">
            <Label htmlFor="school_status_id">Status</Label>
            {!statusesLoading ? (
              <Select
                value={cv.schools[i]?.school_status_id?.toString()}
                onValueChange={(e) => {
                  setArrayData(i, "school_status_id", e);
                }}
              >
                <SelectTrigger className="w-full mt-1 ">
                  <SelectValue placeholder="Choose nationality">
                    {
                      statuses?.find(
                        (nation) =>
                          nation.id == cv.schools[i]?.school_status_id,
                      )?.name
                    }
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {statuses?.map((nation) => (
                    <SelectItem key={nation.id} value={nation.id}>
                      {nation.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            ) : (
              <div className="px-3 py-2 text-sm rounded bg-slate-200">
                Loading . . .
              </div>
            )}
          </div>
        </div>
      ))}
      <div className="flex justify-center mt-4">
        <Button
          onClick={addMoreSchool}
          className="flex items-center gap-1"
          variant="secondary"
        >
          <Plus />
          <span>Add More Education</span>
        </Button>
      </div>
      <div className="flex justify-between mt-8 md:col-span-2">
        <Button
          onClick={() => setTab("exp")}
          className="flex items-center gap-1 bg-transparent rounded-full"
          variant="outline"
        >
          <ChevronLeft />
          <span>Previous</span>
        </Button>
        <Button
          onClick={() => setTab("certificates")}
          className="flex items-center gap-1 bg-transparent rounded-full"
          variant="outline"
        >
          <span>Next</span>
          <ChevronRight />
        </Button>
      </div>
    </div>
  );
};

export default FormEducation;
