import { Job } from "@/types";
import { format } from "date-fns";

const SharedHistory = ({ job }: { job: Job }) => {
  return (
    <div>
      <div className="flex p-3 mb-3">
        <p className="text-xl">Shared History</p>
      </div>
      <div className="grid grid-cols-3 px-3 py-2 text-white rounded-lg bg-foreground hover:bg-foreground dark:text-slate-800">
        <div>Shared Date</div>
        <div>Status</div>
        <div>Amount</div>
      </div>
      {job.share_histories && job.share_histories.length ? (
        job.share_histories.map((history) => (
          <div
            key={history.id}
            className="grid items-center grid-cols-3 px-3 py-2 my-2 rounded-lg dark:border border-slate-700 bg-white/50 dark:bg-background"
          >
            <div>{format(history.created_at, "MMMdd ,yyyy")}</div>
            <div>{history.active ? "Active" : "Unactive"}</div>
            <div>{history.amount_text}</div>
          </div>
        ))
      ) : (
        <p className="py-8 text-center">No Data.</p>
      )}
    </div>
  );
};

export default SharedHistory;
