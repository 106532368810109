import useQuery from "@/hooks/use-query";
// import { Link } from "react-router-dom";

import { IdCard } from "lucide-react";

// import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Applications } from "@/types";
import { Skeleton } from "@/components/ui/skeleton";
import Error from "@/components/ui/error";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

const AppliedList = () => {
  const {
    data: jobs,
    error: error,
    loading: loading,
  }: { data: Applications; error: unknown; loading: boolean } = useQuery(
    "/my-applications",
  );
  if (error) {
    return <Error />;
  }
  return !loading ? (
    <div>
      <div className="flex items-center">
        <h1 className="flex-grow mb-4 text-xl font-semibold">Applied List</h1>
      </div>
      <div>
        <div className="grid grid-cols-6 px-3 py-2 text-white rounded-lg bg-foreground hover:bg-foreground dark:text-slate-800">
          <div>Applicant</div>
          <div>Job Title</div>
          <div>Applied Date</div>
          <div>Status</div>
          <div>Agent Info</div>
          <div>Actions</div>
        </div>
        {jobs.length ? (
          jobs.map((application) => (
            <div
              key={application.id}
              className="grid items-center grid-cols-6 px-3 py-2 my-2 rounded-lg dark:border border-slate-700 bg-white/50 dark:bg-background"
            >
              <div>{application.user ? application.user.name : ""}</div>
              <div>{application.job.title}</div>
              <div>{application.created_at?.slice(0, 10)}</div>
              <div>{application.status}</div>
              <div>
                <Dialog>
                  <DialogTrigger className="px-2 py-1 text-xl text-white rounded-lg bg-tint-background">
                    <IdCard />
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle></DialogTitle>
                      <DialogDescription className="flex flex-col items-center gap-4">
                        <img
                          src="/vite.svg"
                          className="w-[75px] h-[75px] object-cover rounded-lg"
                          alt=""
                        />
                        <h3 className="text-lg text-primary">Jack Lucas</h3>
                      </DialogDescription>
                    </DialogHeader>
                  </DialogContent>
                </Dialog>
              </div>
              <div>
                <Button
                  asChild
                  className="border bg-background dark:bg-foreground border-background text-slate-800"
                >
                  <Link to={`/job-applications/${application.id}?type=agent`}>
                    View Details
                  </Link>
                </Button>
              </div>
            </div>
          ))
        ) : (
          <p className="py-12 text-center">No data.</p>
        )}
      </div>
    </div>
  ) : (
    <div className="p-2">
      <Skeleton className="h-[75px] mb-4" />
      <Skeleton className="h-[75px] mb-4" />
      <Skeleton className="h-[75px] mb-4" />
      <Skeleton className="h-[75px] mb-4" />
      <Skeleton className="h-[75px] mb-4" />
      <Skeleton className="h-[75px] mb-4" />
    </div>
  );
};

export default AppliedList;
