import { Jobs } from "@/types";
import { Button } from "@/components//ui/button";
import { Link } from "react-router-dom";
import { Users } from "lucide-react";
import { format } from "date-fns";

const ListView = ({ jobs }: { jobs: Jobs }) => {
  return (
    <ul>
      {jobs.map((job) => (
        <li
          key={job.id}
          className="grid items-center min-w-[800px] grid-cols-5 p-6 my-4 bg-white rounded-lg dark:bg-background dark:border border-slate-500"
        >
          {/* <img className="w-[75px] h-[75px] rounded" src={job.image?.url} alt={job.title} /> */}
          <h3 className="col-span-2 text-xl font-semibold">{job.title}</h3>
          <p className="flex items-center gap-2">
            <Users /> - {job?.applied_users?.length}
          </p>
          <p>{format(new Date(job.created_at), "dd-MMM-yyyy")}</p>
          <Button
            asChild
            className="border bg-background dark:bg-foreground hover:border-background w-fit text-slate-800"
          >
            <Link to={`/jobs/${job.id}?tab=info`}>View Details</Link>
          </Button>
        </li>
      ))}
    </ul>
  );
};

export default ListView;
