import { Label } from "@/components/ui/label";
import { Application } from "@/types";
import { Info, Square } from "lucide-react";
import { format } from "date-fns";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
// import { useToast } from "@/hooks/use-toast";

const ApplierAppointments = ({ application }: { application: Application }) => {
  // const { toast } = useToast();
  const [selectedTime, setSelectedTime] = useState({
    appointment_id: 0,
    option_id: 0,
  });
  const acceptAppointment = async (id: number) => {
    setLoading(true);
    alert("need to integrate api" + id);
    // const { message } = await response.json();
    // toast({
    //   title: message,
    //   description: message,
    //   variant: response.ok ? "default" : "destructive",
    // });
    // setLoading(false);
    // window.location.reload();
  };
  const [loading, setLoading] = useState(false);
  const rejectAppointment = async (id: number) => {
    setLoading(true);
    alert("Need to integrate api" + id);
    // const { message } = await response.json();
    // if (!response.ok) {
    //   toast({
    //     title: message,
    //     description: message,
    //     variant: "destructive",
    //   });
    // }
    // setLoading(false);
    // window.location.reload();
  };
  useEffect(() => {
    if (
      application.appointments &&
      application.appointments.length &&
      application.appointments[0]["options"]
    ) {
      const lastAppointment =
        application.appointments && application.appointments.length
          ? application.appointments[application.appointments.length - 1]
          : { id: 0 };
      const option =
        lastAppointment.options && lastAppointment.options.length
          ? lastAppointment.options[0]
          : { id: 0 };
      setSelectedTime({
        appointment_id: lastAppointment.id,
        option_id: option.id,
      });
    }
  }, [setSelectedTime, application.appointments]);
  return (
    <div>
      <ul className="grid grid-cols-1 gap-4 lg:grid-cols-3 md:grid-cols-2">
        {application.appointments?.length ? (
          application.appointments.map((app) => (
            <li
              key={app.id}
              className="p-4 bg-white border rounded-lg dark:bg-slate-600 dark:text-white"
            >
              <h3 className="flex justify-between pb-4 border-b">
                <span>Interview Type</span>
                <span>:</span>
                <span>{app.type}</span>
              </h3>
              {application.appointments &&
              application.appointments.length &&
              app.id ==
                application.appointments[application.appointments?.length - 1][
                  "id"
                ] ? (
                <p
                  className={`${
                    app.status == "accepted" || app.status == "rejected"
                      ? "hidden"
                      : "block"
                  } mt-4 text-destructive flex items-center gap-2 text-sm`}
                >
                  <Info width={16} height={16} />
                  <span>Select One - </span>
                </p>
              ) : null}
              {app.status == "accepted" || app.status == "rejected" ? (
                <div>
                  {app.options?.map((option) =>
                    option.status == "ok" ? (
                      <p
                        key={option.id}
                        className="flex items-center gap-2 mt-4"
                      >
                        <Square className="text-white rounded bg-primary" />
                        {option.appointment_time
                          ? format(
                              new Date(option.appointment_time),
                              "MMMM-dd, EEEE (HH:mm bbbb)",
                            )
                          : "Unavailable time"}
                      </p>
                    ) : null,
                  )}
                </div>
              ) : (
                <RadioGroup
                  defaultValue={
                    app.options?.length ? app?.options[0]?.id?.toString() : "0"
                  }
                  value={selectedTime.option_id.toString()}
                  onValueChange={(e) =>
                    setSelectedTime({
                      appointment_id: app.id,
                      option_id: Number(e),
                    })
                  }
                  className="mt-2"
                >
                  {app.options?.map((option) => (
                    <div
                      key={option.id}
                      className="flex items-center my-2 space-x-2"
                    >
                      <RadioGroupItem
                        disabled={
                          !(
                            application.appointments &&
                            application.appointments.length &&
                            app.id ==
                              application.appointments[
                                application.appointments?.length - 1
                              ]["id"]
                          )
                        }
                        value={option.id.toString()}
                        id="option-one"
                      />
                      <Label htmlFor={option.id.toString()}>
                        {option.appointment_time
                          ? format(
                              new Date(option.appointment_time),
                              "MMMM-dd, EEEE (HH:mm bbbb)",
                            )
                          : "Unavailable time"}
                      </Label>
                    </div>
                  ))}
                </RadioGroup>
              )}
              {app.type == "online" ? (
                <p className="my-4">{app.meeting_link}</p>
              ) : (
                <p className="my-4">{app.address}</p>
              )}
              <div className="grid grid-cols-2 gap-4">
                {application.appointments &&
                application.appointments.length &&
                app.id ==
                  application.appointments[
                    application.appointments?.length - 1
                  ]["id"] ? (
                  <div
                    className={
                      app.status == "accepted" || app.status == "rejected"
                        ? "hidden"
                        : "flex gap-4"
                    }
                  >
                    <Button
                      disabled={loading}
                      onClick={() => rejectAppointment(app.id)}
                      variant="outline"
                      className="w-1/2 border border-destructive"
                    >
                      Reject
                    </Button>
                    <Button
                      disabled={loading}
                      onClick={() => acceptAppointment(app.id)}
                      className="w-1/2 text-white bg-green-500 "
                    >
                      Accept
                    </Button>
                  </div>
                ) : null}
              </div>
            </li>
          ))
        ) : (
          <p className="py-12 text-center md:col-span-2 lg:col-span-3">
            No appointment has been made for this application.
          </p>
        )}
      </ul>
    </div>
  );
};

export default ApplierAppointments;
