import AppliedList from "@/components/jobs/details/Appliedlist";
import Comments from "@/components/jobs/details/Comments";
import JobInfo from "@/components/jobs/details/JobInfo";
import SharedHistory from "@/components/jobs/details/SharedHistory";
import { Button } from "@/components/ui/button";
import Error from "@/components/ui/error";
import { Skeleton } from "@/components/ui/skeleton";
import useQuery from "@/hooks/use-query";
import { Job } from "@/types";
import {
  ChevronLeft,
  MessageSquare,
  ReceiptText,
  Share2,
  Users,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const JobDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();
  const id = params.id ? params.id : "";
  const [tab, setTab] = useState<string>(search.get("tab") ?? "info");
  const {
    data: job,
    loading,
    error,
  }: { data: Job; error: unknown; loading: boolean } = useQuery(`jobs/${id}`);
  useEffect(() => {
    setSearch({ tab: tab }, { replace: true });
  }, [tab, setSearch]);
  if (error) {
    return <Error />;
  }
  return !loading ? (
    <div>
      <div className="flex items-center gap-4 mb-4">
        <Button
          size="icon"
          onClick={() => navigate(-1)}
          className="rounded-full"
        >
          <ChevronLeft />
        </Button>
        <Button
          onClick={() => setTab("info")}
          variant={tab == "info" ? "secondary" : "outline"}
          className="flex items-center gap-2 rounded-full"
        >
          <ReceiptText className="w-4 h-4" />
          <span>Job Info</span>
        </Button>
        <Button
          onClick={() => setTab("list")}
          variant={tab == "list" ? "secondary" : "outline"}
          className="flex items-center gap-2 rounded-full"
        >
          <Users className="w-4 h-4" />
          <span>Applied List</span>
        </Button>
        <Button
          onClick={() => setTab("history")}
          variant={tab == "history" ? "secondary" : "outline"}
          className="flex items-center gap-2 rounded-full"
        >
          <Share2 className="w-4 h-4" />
          <span>Shared History</span>
        </Button>
        <Button
          onClick={() => setTab("comments")}
          variant={tab == "comments" ? "secondary" : "outline"}
          className="flex items-center gap-2 rounded-full"
        >
          <MessageSquare className="w-4 h-4" />
          <span>Comments</span>
        </Button>
      </div>
      {tab == "info" ? (
        <JobInfo job={job} />
      ) : tab == "list" ? (
        <AppliedList job={job} />
      ) : tab == "history" ? (
        <SharedHistory job={job} />
      ) : (
        <Comments job={job} />
      )}
    </div>
  ) : (
    <div className="grid grid-cols-1 gap-4 p-2 lg:grid-cols-4">
      <div className="lg:col-span-3">
        <Skeleton className="h-[120px] mb-4" />
        <Skeleton className="h-[120px] mb-4" />
        <Skeleton className="h-[400px] mb-4" />
      </div>
      <div>
        <Skeleton className="h-[300px] mb-4" />
        <Skeleton className="h-[120px] mb-4" />
        <Skeleton className="h-[120px] mb-4" />
        <Skeleton className="h-[120px] mb-4" />
      </div>
    </div>
  );
};

export default JobDetails;
