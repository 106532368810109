import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import StepOne from "@/components/jobs/create/StepOne";
import StepTwo from "@/components/jobs/create/StepTwo";
import StepThree from "@/components/jobs/create/StepThree";
import StepFour from "@/components/jobs/create/StepFour";
import StepFive from "@/components/jobs/create/StepFive";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Job } from "@/types";
import InitialInformation from "@/components/jobs/create/InitialInformation";
import { Button } from "@/components/ui/button";
import { ArrowLeft, Ellipsis, Upload } from "lucide-react";
import ApiService from "@/services/ApiService";
import { useToast } from "@/hooks/use-toast";
import Error from "@/components/ui/error";
import JobPreview from "./JobPreview";
const EditJob = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [tab, setTab] = useState("0");
  const [job, setJob] = useState<Job>();
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [error, setError] = useState<{ [key: string]: string }>();

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement>,
    nextTab: string
  ) => {
    e.preventDefault();
    setTab(nextTab);
  };

  const updateJob = () => {
    setUpdateLoading(true);
    ApiService.put(`jobs/${id}`, job)
      .then((res) => {
        window.sessionStorage.removeItem("initials");
        toast({ title: res.data.message, variant: "success" });
        setUpdateLoading(false);
        navigate(`/jobs/${id}?tab=info`);
      })
      .catch((err) => {
        setUpdateLoading(false);
        setJob({ ...job, errors: err.response?.data?.errors });
        toast({
          title: "Error updating job.",
          description: err.response?.data?.message,
          variant: "destructive"
        });
      });
  };

  useEffect(() => {
    setLoading(true);
    sessionStorage.removeItem("initials");
    ApiService.get(`jobs/${id}`)
      .then((res) => {
        console.log(res);
        const nationalities = res.data.data?.nationalities?.map((n) => n.id);
        const tags = res.data.data?.tags?.map((t) => t.id);
        const benefits = res.data.data?.benefits?.map((b) => b.id);
        console.log(tags);
        setJob({
          ...res.data.data,
          nationality_ids: nationalities ?? [],
          tag_ids: tags ?? [],
          benefit_ids: benefits ?? []
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(err.response?.data?.message);
        setLoading(false);
      });
  }, [id]);

  if (error) {
    return <Error />;
  }
  return !loading ? (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-4">
      <Tabs
        value={tab}
        defaultValue="1"
        className="relative lg:col-span-3 w-full bg-white rounded-lg h-[90vh] overflow-y-scroll hide-scroll p-2 dark:bg-slate-600"
      >
        <Button
          asChild
          className="absolute top-0 left-0"
          size="icon"
          variant="ghost"
        >
          <Link to={`/jobs/${id}`}>
            <ArrowLeft />
          </Link>
        </Button>
        <TabsList className="w-full bg-transparent">
          <TabsTrigger
            onClick={() => setTab("0")}
            className="mx-1 rounded-full"
            value="0"
          >
            Initial Info
          </TabsTrigger>
          <TabsTrigger
            onClick={() => setTab("1")}
            className="mx-1 rounded-full"
            value="1"
          >
            Step 1
          </TabsTrigger>
          <TabsTrigger
            onClick={() => setTab("2")}
            className="mx-1 rounded-full"
            value="2"
          >
            Step 2
          </TabsTrigger>
          <TabsTrigger
            onClick={() => setTab("3")}
            className="mx-1 rounded-full"
            value="3"
          >
            Step 3
          </TabsTrigger>
          <TabsTrigger
            onClick={() => setTab("4")}
            className="mx-1 rounded-full"
            value="4"
          >
            Step 4
          </TabsTrigger>
          <TabsTrigger
            onClick={() => setTab("5")}
            className="mx-1 rounded-full"
            value="5"
          >
            Step 5
          </TabsTrigger>
        </TabsList>
        <TabsContent value="0">
          <InitialInformation setJob={setJob} job={job} setTab={setTab} />
        </TabsContent>
        <TabsContent value="1">
          <StepOne
            setTab={setTab}
            job={job}
            setJob={setJob}
            handleSubmit={handleSubmit}
          />
        </TabsContent>
        <TabsContent value="2">
          <StepTwo
            setTab={setTab}
            job={job}
            setJob={setJob}
            handleSubmit={handleSubmit}
          />
        </TabsContent>
        <TabsContent value="3">
          <StepThree
            setTab={setTab}
            job={job}
            setJob={setJob}
            handleSubmit={handleSubmit}
          />
        </TabsContent>
        <TabsContent value="4">
          <StepFour
            setTab={setTab}
            job={job}
            setJob={setJob}
            handleSubmit={handleSubmit}
          />
        </TabsContent>
        <TabsContent value="5">
          <StepFive
            setTab={setTab}
            job={job}
            setJob={setJob}
            handleSubmit={handleSubmit}
          />
        </TabsContent>
        <div className="flex justify-center my-8">
          <Button
            disabled={updateLoading}
            onClick={updateJob}
            className="flex items-center w-full gap-2"
            size="lg"
          >
            {!updateLoading ? <Upload /> : <Ellipsis />}
            {!updateLoading ? (
              <span>Update Job Details</span>
            ) : (
              <span>Loading</span>
            )}
          </Button>
        </div>
      </Tabs>
      <JobPreview job={job} />
    </div>
  ) : (
    <p className="py-12 text-center">Loading</p>
  );
};

export default EditJob;
