import { Application } from "@/types";
import { useState } from "react";

import {
  User,
  BriefcaseBusiness,
  GraduationCap,
  IdCard,
  Star,
  Mail,
  Calendar,
  Heart,
  Dna,
  Phone,
  Download,
  UserRoundCheck,
  MapPin,
  Signpost,
  Milestone,
  Car,
  Languages,
  Flag,
} from "lucide-react";
import { Link as LinkIcon } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { format } from "date-fns";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import ApplierInformation from "./ApplierInformation";

type Props = {
  application: Application;
  isApplier?: boolean;
};
const UserDetails = ({ application, isApplier = false }: Props) => {
  const [tabs] = useState([
    {
      icon: <User />,
      name: "profile",
    },
    {
      icon: <BriefcaseBusiness />,
      name: "experiences",
    },
    {
      icon: <GraduationCap />,
      name: "education",
    },
    {
      icon: <IdCard />,
      name: "qualifications",
    },
    {
      icon: <Star />,
      name: "skills",
    },
  ]);
  return (
    <div className="flex flex-wrap w-full bg-white rounded-xl dark:bg-slate-700">
      {isApplier ? (
        <div className="w-full bg-background">
          <ApplierInformation application={application} />
        </div>
      ) : null}
      <ul className="relative max-h-[80vh] px-1 py-4 rounded-r-lg sm:p-4 rounded-e-lg bg-slate-100 dark:bg-slate-600">
        {tabs.map((tab) => (
          <li
            key={tab.name}
            className="p-3 my-3 bg-white rounded-full cursor-pointer dark:bg-slate-500"
          >
            <a href={`#${tab.name}`}>{tab.icon}</a>
          </li>
        ))}
        <li className="absolute bottom-12">
          <Dialog>
            <DialogTrigger className="p-3 text-white rounded-full bg-primary">
              <BriefcaseBusiness />
            </DialogTrigger>
            <DialogContent className="lg:max-w-[768px] bg-white dark:bg-slate-700">
              <DialogTitle></DialogTitle>
              <DialogHeader></DialogHeader>
              <DialogDescription></DialogDescription>
              <div>
                <div className="flex flex-col items-center">
                  {/* <img
                    src={application.job.image?.url}
                    className="rounded-lg w-[100px] h-[100px]"
                    alt={application.job?.title}
                  /> */}
                  <h2 className="text-lg font-semibold">
                    {application.job.title}
                  </h2>
                  <p>{format(application.job.created_at, "dd-MMM-yyyy")}</p>
                  <p>Applied : {application.job.anyone}</p>
                </div>
                <div className="grid gap-4 mt-8 lg:grid-cols-2 grid-col-1">
                  <div>
                    <h3>Job Category</h3>
                    <p className="ml-1 text-sm ">
                      {application.job?.sub_category?.name ?? "-"}
                    </p>
                  </div>
                  <div>
                    <h3>Postal Code</h3>
                    <p className="ml-1 text-sm ">
                      {application.job?.postal_code ?? "-"}
                    </p>
                  </div>
                  <div>
                    <h3>State</h3>
                    <p className="ml-1 text-sm ">
                      {application.job?.state?.name ?? "-"}
                    </p>
                  </div>
                  <div>
                    <h3>Address</h3>
                    <p className="ml-1 text-sm ">
                      {application.job?.address ?? "-"}
                    </p>
                  </div>
                  <div>
                    <h3>Salary</h3>
                    {application.job?.salary_range}
                  </div>
                  <div>
                    <h3>Yearly Salary</h3>
                    <p className="ml-1 text-sm ">
                      {application.job?.yearly_salary ?? "-"}¥
                    </p>
                  </div>
                  <div>
                    <h3 className="text-destructive">Job Expired Date</h3>
                    <p className="ml-1 text-sm ">
                      {application.job?.job_expired_date ?? "None"}
                    </p>
                  </div>
                  <div className="flex justify-center col-span-1 lg:col-span-2">
                    <Button variant="secondary">
                      <Link to={`/jobs/${application.job?.id}`}>
                        View Job Details
                      </Link>
                    </Button>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </li>
      </ul>
      <div className="flex-grow max-h-[80vh] pb-4 overflow-y-scroll hide-scroll">
        <section className="flex flex-col items-center py-8">
          <img
            src={application.cv_form?.image?.url}
            className="w-[180px] h-[180px] border rounded-lg object-cover"
            alt="User Profile"
          />
          <h1 className="mt-4 text-xl font-semibold capitalize">
            {application.user?.name}
          </h1>
          <h3 className="text-primary">{application?.cv_form?.occupation}</h3>
        </section>
        {application.cv_form && application.cv_form.name ? (
          <>
            <section id="profile" className="m-1 sm:m-8">
              <h2 className="flex items-center gap-3 text-xl font-semibold uppercase text-primary">
                <User strokeWidth={3} />
                <span>Profile</span>
              </h2>
              <div className="grid gap-6 p-3 py-6 md:grid-cols-2">
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <User />
                    <span>Japanese Name</span>
                  </h3>
                  <p className="ml-8">
                    {application.cv_form.name_japan ?? "-"}
                  </p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <Mail />
                    <span>Email</span>
                  </h3>
                  <p className="ml-8">{application.user?.email}</p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <Calendar />
                    <span>Date of Birth</span>
                  </h3>
                  <p className="ml-8">{application.cv_form.dob ?? "-"}</p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <UserRoundCheck />
                    <span>Age</span>
                  </h3>
                  <p className="ml-8">{application.cv_form.age ?? "-"}</p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <Dna />
                    <span>Gender</span>
                  </h3>
                  <p className="ml-8">{application.cv_form.gender ?? "-"}</p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <Phone />
                    <span>Phone Number</span>
                  </h3>
                  <p className="ml-8">{application.cv_form.phone_no ?? "-"}</p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <Heart />
                    <span>Martial Status</span>
                  </h3>
                  <p className="ml-8">
                    {application.cv_form.martial_status?.name ?? "-"}
                  </p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <User />
                    <span>Facebook Name</span>
                  </h3>
                  <p className="ml-8">
                    {application.cv_form.facebook_name ?? "-"}
                  </p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <LinkIcon />
                    <span>Facebook Link</span>
                  </h3>
                  <p className="ml-8">
                    {application.cv_form.facebook_profile_link ?? "-"}
                  </p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <MapPin />
                    <span>Permanent Address</span>
                  </h3>
                  <p className="ml-8">{application.cv_form.address ?? "-"}</p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <Signpost />
                    <span>Postal Code</span>
                  </h3>
                  <p className="ml-8">
                    {application.cv_form.postal_code ?? "-"}
                  </p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <Milestone />
                    <span>State</span>
                  </h3>
                  <p className="ml-8">
                    {application.cv_form.state?.name ?? "-"}
                  </p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <Calendar />
                    <span>Arrival date</span>
                  </h3>
                  <p className="ml-8">
                    {application.cv_form.arrival_date ?? "-"}
                  </p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <IdCard />
                    <span>Visa Status</span>
                  </h3>
                  <p className="ml-8">
                    {application.cv_form.visa_status?.name ?? "-"}
                  </p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <Calendar />
                    <span>Visa Deadline</span>
                  </h3>
                  <p className="ml-8">
                    {application.cv_form.visa_deadline_date ?? "-"}
                  </p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <Car />
                    <span>Driver License Status</span>
                  </h3>
                  <p className="ml-8">
                    {application.cv_form.driver_license_status?.name ?? "-"}
                  </p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <Languages />
                    <span>Japanese Level</span>
                  </h3>
                  <p className="ml-8">
                    {application.cv_form.japan_level?.name ?? "-"}
                  </p>
                </div>
                <div>
                  <h3 className="flex items-center gap-2 font-semibold">
                    <Flag />
                    <span>Nationality</span>
                  </h3>
                  <p className="ml-8">
                    {application.cv_form.nationaliy?.name ?? "-"}
                  </p>
                </div>
              </div>
            </section>
            <section id="experiences" className="m-1 sm:m-8">
              <h2 className="flex items-center gap-3 text-xl font-semibold uppercase text-primary">
                <BriefcaseBusiness strokeWidth={3} />
                <span>Experience</span>
              </h2>
              {application.cv_form?.work_experiences &&
              application.cv_form?.work_experiences.length ? (
                application.cv_form.work_experiences.map((exp) => (
                  <div
                    key={exp.company_name}
                    className="p-2 py-6 border-b ml-7"
                  >
                    <div className="justify-between sm:flex">
                      <div>
                        <h3 className="text-xl">{exp.company_name}</h3>
                        <h3 className="text-lg">{exp.position}</h3>
                      </div>
                      <p className="flex items-center gap-2 text-lg">
                        <Calendar />
                        <span>
                          {format(exp.start_date, "MMM,yyyy")} -{" "}
                          {format(exp.end_date, "MMM,yyyy")}
                        </span>
                      </p>
                    </div>
                    <p className="mt-4">{exp.why_quit}</p>
                  </div>
                ))
              ) : (
                <p className="ml-10">-</p>
              )}
            </section>
            <section id="education" className="m-1 sm:m-8">
              <h2 className="flex items-center gap-3 text-xl font-semibold uppercase text-primary">
                <GraduationCap strokeWidth={3} />
                <span>Education</span>
              </h2>
              {application.cv_form.schools &&
              application.cv_form.schools.length ? (
                application.cv_form.schools.map((edu) => (
                  <div key={edu.name} className="p-2 py-6 border-b ml-7">
                    <div className="justify-between sm:flex">
                      <div>
                        <h3 className="text-xl">{edu.name}</h3>
                        <h4>{edu.major}</h4>
                      </div>
                      <p className="flex items-center gap-2 text-lg">
                        <Calendar />
                        <span>
                          {format(edu.from, "MMM,yyyy")} -{" "}
                          {format(edu.to, "MMM,yyyy")}
                        </span>
                      </p>
                    </div>
                    <p className="my-4">{edu.school_status?.name}</p>
                  </div>
                ))
              ) : (
                <p className="ml-10">-</p>
              )}
            </section>
            <section id="qualifications" className="m-1 sm:m-8">
              <h2 className="flex items-center gap-3 text-xl font-semibold uppercase text-primary">
                <IdCard strokeWidth={3} />
                <span>Licence . Qualification</span>
              </h2>
              {application.cv_form?.driver_license_status &&
              application.cv_form?.driver_license_status?.name ? (
                <div className="p-2 py-6 border-b ml-7">
                  <div className="items-center justify-between sm:flex">
                    <h3 className="text-xl">Driving Licence</h3>
                    <p className="flex items-center gap-2 text-lg">
                      <Calendar />
                      <span>
                        {application.cv_form?.driver_license_status?.name}
                      </span>
                    </p>
                  </div>
                </div>
              ) : null}
              {application.cv_form.certificates &&
              application.cv_form.certificates.length ? (
                application.cv_form.certificates.map((certi) => (
                  <div key={certi.name} className="p-2 py-6 border-b ml-7">
                    <div className="items-center justify-between sm:flex">
                      <h3 className="text-xl">{certi.name}</h3>
                      <p className="flex items-center gap-2 text-lg">
                        <Calendar />
                        <span>
                          {format(
                            new Date(certi.year, certi.month, 1),
                            "MMM,yyyy",
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="ml-10">-</p>
              )}
            </section>
            <section id="skills" className="m-1 sm:m-8">
              <h2 className="flex items-center gap-3 text-xl font-semibold uppercase text-primary">
                <Star strokeWidth={3} />
                <span>Skills & Hobbies</span>
              </h2>
              <h3 className="mt-4 text-xl ml-9">Skills</h3>
              {application.cv_form.skills &&
              application.cv_form.skills.length ? (
                application.cv_form.skills.map((skill) => (
                  <div key={skill.name} className="p-2 py-6 border-b ml-9">
                    <h4 className="text-lg"> • {skill.name}</h4>
                    <p className="my-2">{skill.description}</p>
                  </div>
                ))
              ) : (
                <p className="ml-9">-</p>
              )}
              <h3 className="mt-4 text-xl ml-9">Hobbies</h3>
              {application.cv_form.hobbies &&
              application.cv_form.hobbies.length ? (
                application.cv_form.hobbies.map((hobby) => (
                  <div key={hobby} className="p-2 py-6 border-b ml-9">
                    <h4 className="text-lg"> • {hobby}</h4>
                  </div>
                ))
              ) : (
                <p className="ml-9">-</p>
              )}
            </section>
          </>
        ) : application.cv_file ? (
          <>
            <div className="flex justify-end">
              <Button asChild>
                <a
                  className="flex items-center gap-2"
                  target="_blank"
                  href={application.cv_file.url}
                  download={application.cv_file.url}
                >
                  <Download />
                  <span>Download cv file</span>
                </a>
              </Button>
            </div>
            <iframe
              key={application.cv_file.id}
              src={application.cv_file.url}
              className="w-full mb-4 lg:w-2/3 xl:w-1/2 mx-auto h-fit aspect-[9/16]"
              width="100px"
              height="100px"
            ></iframe>
          </>
        ) : (
          <p className="py-4 text-center">No Cv form or file is provided.</p>
        )}
      </div>
    </div>
  );
};

export default UserDetails;
