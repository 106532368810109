import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Plus, Square, Trash } from "lucide-react";
import { Label } from "../ui/label";
import {
  Select,
  SelectContent,
  SelectTrigger,
  SelectValue,
  SelectItem,
} from "../ui/select";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../ui/button";
import ApiService from "@/services/ApiService";
import { useToast } from "@/hooks/use-toast";
import { Application } from "@/types";
import { Input } from "../ui/input";
import { format } from "date-fns";
import { CalendarDatePicker } from "../ui/calendar-date-picker";
// import { CalendarDatePicker } from "../ui/calendar-date-picker";

type Props = {
  application: Application;
};

const Appointments = ({ application }: Props) => {
  const { id } = useParams();
  const { toast } = useToast();
  const [newAppointment, setNewAppointment] = useState({
    type: "online",
    job_application_id: id,
    user_id: application.user_id,
    job_id: application.job_id,
    address: "",
    meeting_link: "",
    options: [
      {
        date: format(new Date(), "yyyy-MM-dd"),
        time: "",
        calDate: { from: new Date() }, // for calendar day picker (needs changes)
      },
      {
        date: format(new Date(), "yyyy-MM-dd"),
        time: "",
        calDate: { from: new Date() },
      },
      {
        date: format(new Date(), "yyyy-MM-dd"),
        time: "",
        calDate: { from: new Date() },
      },
    ],
  });
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: unknown }>({});

  const [editApplication, setEditApplication] = useState({
    id: 0,
    meeting_link: "",
    address: "",
  });

  // const [day , setDay] = useState({from : new Date()});

  const setDate = (key: number, value: Date) => {
    setNewAppointment(() => {
      return {
        ...newAppointment,
        options: newAppointment.options.map((op, index) => {
          if (index === key) {
            op.date = format(value, "yyyy-MM-dd");
            op.calDate = { from: value };
          }
          return op;
        }),
      };
    });
  };

  const setTime = (key: number, value: string) => {
    setNewAppointment(() => {
      return {
        ...newAppointment,
        options: newAppointment.options.map((op, index) => {
          if (index === key) {
            op.time = value;
          }
          return op;
        }),
      };
    });
    console.log(newAppointment.options);
  };

  const addMoreOption = () => {
    setNewAppointment(() => {
      return {
        ...newAppointment,
        options: [
          ...newAppointment.options,
          {
            date: format(new Date(), "yyyy-MM-dd"),
            time: "",
            calDate: { from: new Date() },
          },
        ],
      };
    });
  };

  const removeOption = (index: number) => {
    const options = newAppointment.options.filter((_, i) => i !== index);
    setNewAppointment(() => {
      return {
        ...newAppointment,
        options: options,
      };
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const obj = {
      job_application_id: newAppointment.job_application_id,
      user_id: newAppointment.user_id,
      job_id: newAppointment.job_id,
      type: newAppointment.type,
      address: newAppointment.address,
      meeting_link: newAppointment.meeting_link,
      options: newAppointment.options.map(
        (option) => `${option.date} ${option.time}`,
      ),
    };
    console.log(obj);
    console.log(newAppointment);
    ApiService.post("appointments", obj)
      .then((res) => {
        setLoading(false);
        toast({
          title: res.data.message,
          description: res.data.message,
          variant: "success",
        });
        setShowDialog(false);
        window.location.reload();
      })
      .catch((err) => {
        setErrors(err?.response?.data?.errors);
        setLoading(false);
        toast({
          title: err.response.data?.message,
          description: err.response.data?.message,
          variant: "destructive",
        });
      });
  };

  const handleUpdate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    ApiService.put(`appointments/${editApplication.id}`, editApplication)
      .then((res) => {
        setLoading(false);
        toast({
          title: res.data.message,
          description: res.data.message,
          variant: "success",
        });
      })
      .catch((err) => {
        setLoading(false);
        setErrors(err?.response?.data?.errors);
        toast({
          title: err.response.data?.message,
          description: err.response.data?.message,
          variant: "destructive",
        });
      });
  };

  useEffect(() => {
    setErrors({});
  }, []);

  return (
    <div className="relative min-h-[85vh]">
      <Dialog open={showDialog} onOpenChange={setShowDialog}>
        <DialogContent
          onPointerDownOutside={(e) => e.preventDefault()}
          className="md:min-w-[800px] bg-white dark:bg-slate-700"
        >
          <DialogHeader>
            <DialogTitle>Create Appointment</DialogTitle>
            <DialogDescription asChild>
              <form onSubmit={handleSubmit}>
                <div className="my-4">
                  <Label htmlFor="type">Appointment Type</Label>
                  <Select
                    value={newAppointment.type}
                    onValueChange={(e) => {
                      setNewAppointment({ ...newAppointment, type: e });
                    }}
                  >
                    <SelectTrigger className="w-full mt-1 ">
                      <SelectValue placeholder="online" />
                    </SelectTrigger>
                    <SelectContent defaultValue="online">
                      <SelectItem value="online">Online</SelectItem>
                      <SelectItem value="offline">Offline</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                {newAppointment.type == "offline" ? (
                  <div className="my-4">
                    <Label htmlFor="address">Address</Label>
                    <Input
                      value={newAppointment.address}
                      onChange={(e) => {
                        setNewAppointment({
                          ...newAppointment,
                          address: e.target.value,
                        });
                      }}
                      className="mt-1"
                      type="text"
                      placeholder="address"
                    />
                  </div>
                ) : (
                  <div className="my-4">
                    <Label htmlFor="meeting_link">Meeting Link</Label>
                    <Input
                      value={newAppointment.meeting_link}
                      onChange={(e) => {
                        setNewAppointment({
                          ...newAppointment,
                          meeting_link: e.target.value,
                        });
                      }}
                      className="mt-1"
                      type="text"
                      placeholder="meeting link"
                    />
                  </div>
                )}
                <Label className="text-destructive">
                  Options (Create at least 3 dates)
                </Label>
                {newAppointment.options.map((option, i) => (
                  <div key={i} className="my-4">
                    <div className="flex items-center gap-2">
                      {/* <input
                        value={option.date}
                        onChange={(e) => setDate(i, e.target.value)}
                        className="w-[45%] px-3 py-2.5 mt-1 border-0 rounded-lg outline-none h-fit bg-slate-100 dark:bg-slate-300"
                        type="date"
                        placeholder="01-01-2024"
                      /> */}
                      <CalendarDatePicker
                        date={option.calDate}
                        onDateSelect={({ from }) => setDate(i, from)}
                        variant="outline"
                        numberOfMonths={1}
                        className="flex justify-start w-full border-0 hover:bg-slate-100 dark:hover:bg-slate-300 hover:text-slate-700 bg-slate-100 dark:bg-slate-300 text-slate-800"
                      />
                      <input
                        value={option.time}
                        onChange={(e) => setTime(i, e.target.value)}
                        className="w-[45%] px-3 py-2.5 mt-1 border-0 rounded-lg outline-none h-fit bg-slate-100 dark:bg-slate-300"
                        type="time"
                        placeholder="12:00"
                      />
                      {i > 2 ? (
                        <span
                          onClick={() => removeOption(i)}
                          className="p-2.5 text-slate-800 dark:text-white cursor-pointer mt-2 rounded-lg bg-secondary"
                        >
                          <Trash />
                        </span>
                      ) : null}
                    </div>
                  </div>
                ))}
                {errors.type ? (
                  <p className="font-semibold text-destructive">Error.</p>
                ) : null}
                <div className="flex justify-center my-4">
                  <span onClick={addMoreOption} className="cursor-pointer">
                    Add More Options
                  </span>
                </div>
                <Button
                  disabled={loading}
                  type="submit"
                  className="w-full my-4"
                >
                  Confirm
                </Button>
              </form>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
      <ul className="grid grid-cols-1 gap-4 md:grid-cols-3">
        {application.appointments.length ? (
          application.appointments.map((app) => (
            <li
              key={app.id}
              className="p-4 bg-white rounded-lg shadow dark:bg-slate-700 dark:text-white"
            >
              <h3 className="flex justify-between pb-4 mb-2 border-b">
                <span>Interview Type</span>
                <span>:</span>
                <span>{app.type}</span>
              </h3>
              <strong
                className={`${
                  app.status == "rejected"
                    ? "text-destructive"
                    : "text-green-500"
                } mt-2`}
              >
                {app.status == "rejected"
                  ? "Rejected"
                  : app.status == "accepted"
                    ? "Accepted"
                    : null}
              </strong>
              {app.options?.map((option) => (
                <div className="flex items-center gap-2 my-4" key={option.id}>
                  <Square
                    className={`${
                      option.status == "ok"
                        ? "bg-primary rounded text-white"
                        : ""
                    }`}
                  />
                  <Label className="text-base">
                    {format(
                      new Date(option.appointment_time),
                      "MMMM-dd, EEEE (HH:mm bbbb)",
                    )}
                  </Label>
                </div>
              ))}
              <hr />
              {app.type == "online" ? (
                <p className="my-4">{app.meeting_link}</p>
              ) : (
                <p className="my-4">{app.address}</p>
              )}
              <div className="flex justify-center">
                <Dialog>
                  <DialogTrigger
                    onClick={() =>
                      setEditApplication({ ...editApplication, id: app.id })
                    }
                    asChild
                  >
                    {app.type == "online" ? (
                      <Button>Add or Update Meeting Link</Button>
                    ) : (
                      <Button>Add or Update Address</Button>
                    )}
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>
                        {app.type == "online"
                          ? "Add or Update Meeting Link"
                          : "Add or Update Address Link"}
                      </DialogTitle>
                      <DialogDescription></DialogDescription>
                      <form onSubmit={handleUpdate}>
                        {app.type == "online" ? (
                          <div>
                            <Label>Meeting Link</Label>
                            <Input
                              value={editApplication.meeting_link}
                              onChange={(e) =>
                                setEditApplication({
                                  ...editApplication,
                                  meeting_link: e.target.value,
                                })
                              }
                              className="w-full mt-1"
                              placeholder="meeting link"
                            />
                          </div>
                        ) : (
                          <div>
                            <Label>Address</Label>
                            <Input
                              value={editApplication.address}
                              onChange={(e) =>
                                setEditApplication({
                                  ...editApplication,
                                  address: e.target.value,
                                })
                              }
                              className="w-full mt-1"
                              placeholder="address"
                            />
                          </div>
                        )}
                        <div className="flex justify-end mt-4">
                          <Button disabled={loading} type="submit">
                            Update
                          </Button>
                        </div>
                      </form>
                    </DialogHeader>
                  </DialogContent>
                </Dialog>
              </div>
            </li>
          ))
        ) : (
          <p className="py-12 text-center md:col-span-3 text-destructive">
            No appointment for this application.
          </p>
        )}
      </ul>
      <div className="absolute bottom-4 right-4">
        <Button
          onClick={() => setShowDialog(true)}
          className="flex items-center gap-2 rounded-full dark:bg-white dark:text-slate-800"
          variant="secondary"
          size="lg"
        >
          <Plus className="text-primary" />
          <span>New appointment</span>
        </Button>
      </div>
    </div>
  );
};

export default Appointments;
