import { Info, RotateCw } from "lucide-react";
import { Button } from "./button";

const Error = () => {
  return (
    <div className="flex flex-col py-24 max-w-[600px] mx-auto rounded-lg bg-destructive items-center justify-center gap-4 text-white">
      <Info />
      <p className="text-center">An error has oocured. Please try again.</p>
      <Button
        onClick={() => window.location.reload()}
        className="flex items-center gap-2 mt-4 bg-white text-destructive"
      >
        <RotateCw />
        <span>Reload Page</span>
      </Button>
    </div>
  );
};

export default Error;
