import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Job } from "@/types";
import { useState } from "react";
import useQuery from "@/hooks/use-query";
// import JoditEditor from "jodit-react";

type Props = {
  job: Job;
  setJob: React.Dispatch<React.SetStateAction<Job>>;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>, nextTab: string) => void;
  setTab: React.Dispatch<React.SetStateAction<string>>;
};

const StepOne = ({ job, setJob, handleSubmit, setTab }: Props) => {
  const { data: states, loading: statesLoading } = useQuery(`states`);
  const { data: types, loading: typesLoading } = useQuery(`job-types`);
  const { data: levels, loading: levelsLoading } = useQuery(`japan-levels`);
  const { data: categories, loading: categoriesLoading } =
    useQuery(`job-categories`);
  const [subCategories, setSubCategories] = useState([]);

  // const editor = useRef(null);
  // const options = [
  //   "bold",
  //   "italic",
  //   "|",
  //   "ul",
  //   "ol",
  //   "|",
  //   "fontsize",
  //   "|",
  //   "outdent",
  //   "indent",
  //   "align",
  //   "|",
  //   "hr",
  //   "|",
  //   "fullsize",
  //   "brush",
  //   "|",
  //   "table",
  //   "link",
  //   "|",
  //   "undo",
  //   "redo"
  // ];
  // const config = useMemo(
  //   () => ({
  //     readonly: false,
  //     placeholder: "Enter text here...",
  //     buttons: options,
  //     buttonsMD: options,
  //     buttonsSM: options,
  //     buttonsXS: options
  //   }),
  //   []
  // );

  return (
    <form onSubmit={(e) => handleSubmit(e, "2")}>
      <div className="mb-4">
        <Label htmlFor="title">Job Title *</Label>
        <Input
          value={job.title}
          onChange={(e) => {
            setJob({ ...job, title: e.target.value });
          }}
          className="mt-1"
          type="text"
          placeholder="title"
        />
        {job.errors?.title && (
          <span className="text-destructive">{job.errors.title}</span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="description">Job Description *</Label>
        {/* <JoditEditor
          ref={editor}
          value={job.description}
          config={config}
          onBlur={(newContent) => setJob({ ...job, description: newContent })}
          onChange={(newContent) => setJob({ ...job, description: newContent })}
          className="mt-1"
        /> */}
        <Textarea
          value={job.description}
          onChange={(e) => {
            setJob({ ...job, description: e.target.value });
          }}
          className="w-full mt-1 "
          rows={5}
          placeholder="job description"
        />
        {job.errors?.description && (
          <span className="text-destructive">{job.errors.description}</span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="requirements">Job Requirements.</Label>
        <Textarea
          value={job.requirements}
          onChange={(e) => {
            setJob({ ...job, requirements: e.target.value });
          }}
          className="w-full mt-1 "
          rows={5}
          placeholder="job requirements"
        />
        {job.errors?.requirements && (
          <span className="text-destructive">{job.errors.requirements}</span>
        )}
      </div>
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <Label htmlFor="min_age">Minimum Age</Label>
          <Input
            type="number"
            placeholder="0"
            value={job.min_age}
            onChange={(e) =>
              setJob({ ...job, min_age: Number(e.target.value) })
            }
          />
        </div>
        <div>
          <Label htmlFor="max_age">Maximum Age</Label>
          <Input
            type="number"
            placeholder="0"
            value={job.max_age}
            onChange={(e) =>
              setJob({ ...job, max_age: Number(e.target.value) })
            }
          />
        </div>
      </div>
      <div className="mb-6">
        <Label htmlFor="job_type">Job Type</Label>
        {!typesLoading ? (
          <Select
            value={job.job_type_id ? job.job_type_id.toString() : "1"}
            onValueChange={(e) => {
              setJob({ ...job, job_type_id: Number(e) });
            }}
          >
            <SelectTrigger className="w-full mt-1 ">
              <SelectValue placeholder="Choose category">
                {types?.find((t) => t.id == job.job_type_id)?.name}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {types.map((t) => (
                <SelectItem key={t.id} value={t.id}>
                  {t.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        ) : (
          <div className="px-3 py-2 rounded bg-slate-200">Loading . . .</div>
        )}
        {job.errors?.job_type_id && (
          <span className="text-destructive">{job.errors.job_type_id}</span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="japan_level">Japanese Level</Label>
        <Select
          value={job.japan_level_id?.toString()}
          onValueChange={(e) => {
            setJob({ ...job, japan_level_id: Number(e) });
          }}
        >
          <SelectTrigger className="w-full mt-1">
            <SelectValue placeholder="Choose Japanese Level">
              {
                levels?.find((level) => level.id == job.japan_level_id)
                  ?.description
              }
            </SelectValue>
          </SelectTrigger>
          <SelectContent defaultValue="1">
            {levelsLoading ? (
              <SelectItem value="0">Loading</SelectItem>
            ) : (
              levels.map((level) => (
                <SelectItem key={level.id} value={level.id.toString()}>
                  {level.name}
                </SelectItem>
              ))
            )}
          </SelectContent>
        </Select>
      </div>
      <div className="mb-6">
        <Label htmlFor="postal_code">Postal Code</Label>
        <Input
          value={job.postal_code ?? ""}
          onChange={(e) => {
            setJob({ ...job, postal_code: e.target.value });
          }}
          className="mt-1"
          type="text"
          placeholder="postal code"
        />
        {job.errors?.postal_code && (
          <span className="text-destructive">{job.errors.postal_code}</span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="state_id">State *</Label>
        {!statesLoading ? (
          <Select
            value={job.state_id.toString()}
            onValueChange={(e) => {
              setJob({ ...job, state_id: Number(e) });
            }}
          >
            <SelectTrigger className="w-full mt-1 ">
              <SelectValue placeholder="Choose category">
                {states?.find((s) => s.id == job.state_id)?.name}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {states?.map((state) => (
                <SelectItem key={state.id} value={state.id}>
                  {state.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        ) : (
          <div className="px-3 py-2 rounded bg-slate-200">Loading . . .</div>
        )}
        {job.errors?.state_id && (
          <span className="text-destructive">{job.errors.state_id}</span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="address">Job Address *</Label>
        <Textarea
          value={job.address}
          onChange={(e) => {
            setJob({ ...job, address: e.target.value });
          }}
          className="w-full mt-1 "
          placeholder="address"
        />
        {job.errors?.address && (
          <span className="text-destructive">{job.errors.address}</span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="number_of_hire">
          Number of people to hire on this job *
        </Label>
        <Input
          value={job.anyone}
          onChange={(e) => {
            setJob({ ...job, anyone: Number(e.target.value) });
          }}
          className="mt-1"
          type="number"
          placeholder="number of people to hire"
        />
        {job.errors?.anyone && (
          <span className="text-destructive">{job.errors.anyone}</span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="category">Job category</Label>
        {!categoriesLoading ? (
          <Select
            value={job.category_id ? job.category_id.toString() : "0"}
            onValueChange={(e) => {
              setJob({ ...job, category_id: Number(e) });
              setSubCategories(() => {
                const cat = categories?.find((c) => c.id == e);
                if (cat) {
                  return cat.children;
                } else {
                  return [];
                }
              });
            }}
          >
            <SelectTrigger className="w-full mt-1 ">
              <SelectValue placeholder="Choose category">
                {categories?.find((c) => c.id == job.category_id)?.name}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {categories.map((category) => (
                <SelectItem key={category.id} value={category.id}>
                  {category.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        ) : (
          <div className="px-3 py-2 rounded bg-slate-200">Loading . . .</div>
        )}
        {job.errors?.category_id && (
          <span className="text-destructive">{job.errors.category_id}</span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="sub_category">Job sub category *</Label>
        <Select
          value={job.sub_category_id ? job.sub_category_id.toString() : "0"}
          onValueChange={(e) => {
            setJob({ ...job, sub_category_id: Number(e) });
          }}
        >
          <SelectTrigger className="w-full mt-1 ">
            <SelectValue placeholder="Choose category">
              {subCategories.find((c) => c.id == job.sub_category_id)?.name}
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            {subCategories.map((category) => (
              <SelectItem key={category.id} value={category.id}>
                {category.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        {job.errors?.sub_category_id && (
          <span className="text-destructive">{job.errors.sub_category_id}</span>
        )}
      </div>
      <div className="flex justify-between">
        <span
          onClick={() => setTab("0")}
          className="flex items-center gap-1 px-6 border rounded-full cursor-pointer"
        >
          <ChevronLeft className="w-5 h-5" />
          <span>Previous</span>
        </span>
        <Button
          type="submit"
          className="flex items-center gap-2 px-6 rounded-full"
        >
          <span>Next</span>
          <ChevronRight className="w-5 h-5" />
        </Button>
      </div>
    </form>
  );
};

export default StepOne;
