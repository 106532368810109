import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import ApiService from "@/services/ApiService";
import { Job } from "@/types";
import { format } from "date-fns";
import { Clock, Send } from "lucide-react";
import { useState } from "react";

const Comments = ({ job }: { job: Job }) => {
  const [comments, setComments] = useState(job.comments);
  const [comment, setComment] = useState({
    comment: "",
  });
  const uploadComment = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    ApiService.post(`/jobs/${job.id}/comment`, comment).then((res) => {
      setComment({ ...comment, comment: "" });
      setComments([...comments, res.data.data]);
    });
  };
  return (
    <div className="p-4 bg-white rounded dark:bg-slate-700">
      <ul className="max-h-[600px] pb-4 overflow-y-scroll">
        {comments && comments.length
          ? comments.map((comment, i) => (
              <li
                key={comment.id}
                className="flex flex-wrap gap-4 my-2 rounded"
              >
                <p className="w-full text-sm text-center ">
                  {i > 0 &&
                  format(comment.created_at, "MMddyyyy") ==
                    format(comments[i - 1]["created_at"], "MMddyyyy")
                    ? null
                    : format(comment.created_at, "MMMdd , yyyy")}
                </p>
                <span className="px-4 py-3 rounded-full h-fit bg-slate-200 dark:bg-slate-600">
                  {comment.commentable ? comment.commentable.name[0] : "A"}
                </span>
                <p className="p-3 pb-8 relative min-w-[100px] rounded w-fit max-w-[90%] bg-slate-100 dark:bg-slate-600">
                  {comment.comment}
                  <span className="absolute flex items-center gap-1 text-sm dark:text-slate-300 text-slate-600 right-2 bottom-1">
                    <Clock className="w-4 h-4" />
                    {format(comment.created_at, "H-mm")}
                  </span>
                </p>
              </li>
            ))
          : (
            <p className="py-12 text-center text-destructive">No comments yet.</p>
          )}
      </ul>
      <form onSubmit={uploadComment} className="flex gap-4">
        <div className="flex-grow">
          <Input
            value={comment.comment}
            onChange={(e) =>
              setComment({ ...comment, comment: e.target.value })
            }
            placeholder="Say something to other admins"
            className="w-full"
          />
        </div>
        <Button
          type="submit"
          size="icon"
          variant="secondary"
          className="w-10 h-10"
        >
          <Send />
        </Button>
      </form>
    </div>
  );
};

export default Comments;
