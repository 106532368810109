import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { CvForm } from "@/types";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import FormBasics from "@/components/cv-list/FormBasics";
import FormExperiences from "@/components/cv-list/FormExperiences";
import FormEducation from "@/components/cv-list/FormEducation";
import FormSkills from "@/components/cv-list/FormSkills";
import FormCertificates from "@/components/cv-list/FormCertificates";
import { ArrowLeft, Ellipsis, Upload } from "lucide-react";
import ApiService from "@/services/ApiService";
import { useToast } from "@/hooks/use-toast";
import { useNavigate, useParams } from "react-router-dom";
import FormImage from "@/components/cv-list/FormImage";
import { format } from "date-fns";

const CreateCv = () => {
  const { id } = useParams();
  const redirectJob = window.sessionStorage.getItem("job");
  const navigate = useNavigate();
  const { toast } = useToast();
  const [tab, setTab] = useState("image");
  const [cv, setCv] = useState<CvForm>({
    name: "",
    image_id: 0,
    name_japan: "",
    label: "",
    occupation: "",
    gender: "male",
    age: 1,
    dob: format(new Date(), "yyyy-MM-dd"),
    nationality_id: 1,
    martial_status_id: 2,
    phone_no: "",
    email: "",
    facebook_name: "",
    facebook_profile_link: "",
    postal_code: "",
    state_id: 1,
    address: "",
    visa_status_id: 1,
    visa_deadline_date: format(new Date(), "yyyy-MM-dd"),
    arrival_date: format(new Date(), "yyyy-MM-dd"),
    japan_level_id: 1,
    driver_license_status_id: 1,
    other_personal_staff: "",
    work_experiences: [
      {
        company_name: "",
        position: "",
        start_date: "",
        end_date: "",
        why_quit: "",
      },
    ],
    hobbies: [""],
    schools: [
      {
        name: "",
        major: "",
        from: new Date().toString(),
        to: new Date().toString(),
        school_status_id: 1,
      },
    ],
    certificates: [
      {
        name: "",
        year: 2024,
        month: 1,
        date: new Date(),
      },
    ],
    skills: [
      {
        name: "",
      },
    ],
  });
  const [createLoading, setCreateLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const upladCv = () => {
    setCreateLoading(true);
    const obj = cv;
    ApiService.post(`agent-users/${id}/cv-forms`, obj)
      .then((res) => {
        setCreateLoading(false);
        toast({
          title: res.data?.message,
          description: res.data?.message,
          variant: "success",
        });
        if (redirectJob) {
          window.sessionStorage.removeItem("job");
          navigate(`/shared-jobs/${redirectJob}`);
        } else {
          navigate(`/cv-list/${id}?tab=forms`);
        }
      })
      .catch((err) => {
        setCreateLoading(false);
        setErrors(err.response?.data?.errors);
        toast({
          title: err.response.data?.message,
          description: err.response.data?.message,
          variant: "destructive",
        });
      });
  };

  return (
    <div className="p-2 bg-white rounded-lg dark:bg-slate-800">
      <div className="flex items-center gap-2">
        <Button onClick={() => navigate(-1)} size="icon" variant="ghost">
          <ArrowLeft />
        </Button>
        <h1 className="text-2xl">Create Cv Form</h1>
      </div>
      <Tabs value={tab} defaultValue="account">
        <TabsList className="w-full bg-transparent">
          <TabsTrigger onClick={() => setTab("image")} value="image">
            Image
          </TabsTrigger>
          <TabsTrigger onClick={() => setTab("basics")} value="basics">
            Basics
          </TabsTrigger>
          <TabsTrigger onClick={() => setTab("exp")} value="exp">
            Experiences
          </TabsTrigger>
          <TabsTrigger onClick={() => setTab("edu")} value="edu">
            Education
          </TabsTrigger>
          <TabsTrigger
            onClick={() => setTab("certificates")}
            value="certificates"
          >
            Certificates
          </TabsTrigger>
          <TabsTrigger onClick={() => setTab("skills")} value="skills">
            Skills
          </TabsTrigger>
        </TabsList>
        <TabsContent value="image">
          <FormImage errors={errors} setTab={setTab} cv={cv} setCv={setCv} />
        </TabsContent>
        <TabsContent value="basics">
          <FormBasics errors={errors} setTab={setTab} cv={cv} setCv={setCv} />
        </TabsContent>
        <TabsContent value="exp">
          <FormExperiences
            errors={errors}
            setTab={setTab}
            cv={cv}
            setCv={setCv}
          />
        </TabsContent>
        <TabsContent value="edu">
          <FormEducation
            errors={errors}
            setTab={setTab}
            cv={cv}
            setCv={setCv}
          />
        </TabsContent>
        <TabsContent value="certificates">
          <FormCertificates
            errors={errors}
            setTab={setTab}
            cv={cv}
            setCv={setCv}
          />
        </TabsContent>
        <TabsContent value="skills">
          <FormSkills errors={errors} setTab={setTab} cv={cv} setCv={setCv} />
        </TabsContent>
      </Tabs>
      <div className="flex justify-center my-4">
        <Button
          onClick={upladCv}
          disabled={createLoading}
          className="flex items-center w-full gap-2 md:w-1/2"
        >
          {!createLoading ? <Upload /> : <Ellipsis />}
          {!createLoading ? <span>Upload Cv Now</span> : <span>Loading</span>}
        </Button>
      </div>
    </div>
  );
};

export default CreateCv;
