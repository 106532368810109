import { Button } from "@/components/ui/button";
import useQuery from "@/hooks/use-query";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import Autoplay from "embla-carousel-autoplay";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { ChevronDown, ChevronLeft, ChevronRight } from "lucide-react";
import JobCard from "@/components/shared-jobs/JobCard";
import { useLocation, useSearchParams } from "react-router-dom";
import { Skeleton } from "@/components/ui/skeleton";
import Error from "@/components/ui/error";

const SharedJobs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const {
    data: jobs,
    loading,
    error,
    meta,
  } = useQuery(
    "shared-jobs",
    { page: searchParams.get("page"), per_page: searchParams.get("per_page") },
    location.search,
  );

  const paginate = (page: string | number, perPage: string | number) => {
    setSearchParams({ page: page.toString(), per_page: perPage.toString() });
  };
  const {
    data: categories,
    error: categoriesError,
    loading: categoriesLoading,
  } = useQuery("/job-categories");

  if (error) {
    return <Error />;
  }

  return (
    <div>
      <div>
        <h2 className="text-xl font-semibold">Job Categories</h2>
        <div>
          {categoriesLoading ? (
            <div className="flex gap-4 mb-8">
              <Skeleton className="h-[50px] w-[150px] rounded-full" />
              <Skeleton className="h-[50px] w-[150px] rounded-full" />
              <Skeleton className="h-[50px] w-[150px] rounded-full" />
              <Skeleton className="h-[50px] w-[150px] rounded-full" />
            </div>
          ) : categoriesError ? (
            <div className="text-center">Error fetching categories.</div>
          ) : (
            <div className="items-baseline justify-between w-full overflow-hidden sm:flex">
              <Carousel
                opts={{
                  align: "start",
                  loop: true,
                }}
                plugins={[
                  Autoplay({
                    delay: 4000,
                  }),
                ]}
                className="sm:w-[450px] w-[300px] md:w-[500px] xl:w-[900px] ml-[54px] mt-4"
              >
                <CarouselContent>
                  {categories.map((category) => (
                    <CarouselItem
                      key={category.id}
                      className="md:basis-1/3 xl:basis-1/4 basis-1/2"
                    >
                      <Button className="w-full rounded-full bg-slate-700 dark:bg-slate-700">
                        {category.name}
                      </Button>
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
              </Carousel>
              <DropdownMenu>
                <DropdownMenuTrigger className="p-1.5 px-4 text-lg flex items-center gap-3 justify-between w-[135px] text-white rounded-full h-fit bg-primary">
                  <span>Filter</span>
                  <ChevronDown />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuLabel>My Account</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>Profile</DropdownMenuItem>
                  <DropdownMenuItem>Billing</DropdownMenuItem>
                  <DropdownMenuItem>Team</DropdownMenuItem>
                  <DropdownMenuItem>Subscription</DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          )}
        </div>
      </div>
      <div className="mt-8">
        <h1 className="flex items-center gap-2 mb-4 text-2xl">
          <span>Recommended Jobs</span> <ChevronDown />
        </h1>
        {!loading ? (
          <div>
            <ul className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
              {jobs.map((job) => (
                <JobCard job={job} key={job.id} />
              ))}
            </ul>
            <div className="flex justify-center gap-2 mt-6">
              <Button
                size="icon"
                variant="outline"
                disabled={meta.current_page < 2}
                onClick={() => {
                  paginate(meta.current_page - 1, meta.per_page);
                }}
              >
                <ChevronLeft />
              </Button>
              <Button
                size="icon"
                variant="outline"
                disabled={meta.current_page >= meta.last_page}
                onClick={() => {
                  paginate(meta.current_page + 1, meta.per_page);
                }}
              >
                <ChevronRight />
              </Button>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            <Skeleton className="h-[270px]" />
            <Skeleton className="h-[270px]" />
            <Skeleton className="h-[270px]" />
            <Skeleton className="h-[270px]" />
            <Skeleton className="h-[270px]" />
            <Skeleton className="h-[270px]" />
          </div>
        )}
      </div>
    </div>
  );
};

export default SharedJobs;
