import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "./button";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  header: string;
  message: string;
  action: () => void;
};

const ConfirmDialog = ({ open, setOpen, action, header, message }: Props) => {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="bg-white lg:min-w-[768px] dark:bg-slate-600 text-slate-800 dark:text-white">
        <DialogHeader>
          <DialogTitle>{header}</DialogTitle>
          <DialogDescription>{message}</DialogDescription>
          <div className="flex items-center justify-end gap-4 pt-8">
            <Button onClick={() => setOpen(false)} variant="secondary">
              Cancel
            </Button>
            <Button onClick={action}>Confirm</Button>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDialog;
