import { useCallback, useState } from "react";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import List from "./List";
import UserDetails from "./UserDetails";
import ConfirmApplication from "./ConfirmApplication";
import { Button } from "../ui/button";
import { DialogClose } from "@radix-ui/react-dialog";
import ApiService from "@/services/ApiService";
import { useToast } from "@/hooks/use-toast";
import { Cv, CvForm } from "@/types";

type Props = {
  jobId: number | string;
};
const ApplyJob = ({ jobId }: Props) => {
  const { toast } = useToast();
  const [tab, setTab] = useState<"list" | "cv" | "apply" | "success">("list");
  const [selectedUser, setSelectedUser] = useState<{ [key: string]: unknown }>(
    {},
  );
  const [selectedCv, setSelectedCv] = useState<Cv | null>(null);
  const [selectedForm, setSelectedForm] = useState<CvForm | null>(null);
  const [information, setInformation] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const selectUser = (user: { [key: string]: unknown }) => {
    setSelectedUser(user);
    setTab("cv");
  };
  const isCv = (cv: Cv | CvForm, type: "file" | "form"): cv is Cv => {
    return type === "file";
  };

  const isCvForm = (cv: Cv | CvForm, type: "file" | "form"): cv is CvForm => {
    return type === "form";
  };

  const selectCv = useCallback((type: "file" | "form", cv: Cv | CvForm) => {
    if (isCv(cv, type)) {
      setSelectedForm(null);
      setSelectedCv(cv);
    } else if (isCvForm(cv, type)) {
      setSelectedForm(cv);
      setSelectedCv(null);
    }
  }, []);
  // const selectCv = (cv: {[key : string] : unknown}) => {
  //   setSelectedCv(cv);
  //   setTab("apply");
  // };
  const goBack = (t: "list" | "cv" | "apply" | "success") => {
    setTab(t);
  };
  const apply = () => {
    setLoading(true);
    console.log(selectedUser, selectedCv, information);
    const obj = {
      agent_user_id: selectedUser.id,
      cv_file_id: selectedCv?.id,
      cv_form_id: selectedForm?.id,
      description: information,
    };
    ApiService.post(`jobs/${jobId}/apply`, obj)
      .then((res) => {
        console.log(res);
        toast({
          title: res.data?.message,
          description: res.data?.message,
          variant: "success",
        });
        setTab("success");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast({
          title: err.response.data?.message,
          description: err.response.data?.message,
          variant: "destructive",
        });
      });
  };
  return (
    <div className="h-full" key={jobId}>
      <Tabs className="h-full" value={tab}>
        <TabsList className="hidden">
          <TabsTrigger value="list">Users List</TabsTrigger>
          <TabsTrigger value="cv">Cv List</TabsTrigger>
          <TabsTrigger value="apply">Confirmation</TabsTrigger>
          <TabsTrigger value="success">Success</TabsTrigger>
        </TabsList>
        <TabsContent value="list">
          <List selectUser={selectUser} />
        </TabsContent>
        <TabsContent className="h-full" value="cv">
          <UserDetails
            jobId={jobId}
            setTab={setTab}
            selectedCv={selectedCv}
            selectedForm={selectedForm}
            selectCv={selectCv}
            user={selectedUser}
          />
        </TabsContent>
        <TabsContent value="apply">
          <ConfirmApplication
            information={information}
            setInformation={setInformation}
            apply={apply}
            user={selectedUser}
            cv={selectedCv}
            cvForm={selectedForm}
            loading={loading}
            goBack={goBack}
          />
        </TabsContent>
        <TabsContent
          className="flex flex-col items-center justify-center h-full gap-4"
          value="success"
        >
          <img
            src="/success.png"
            className="max-w-[200px] w-1/2 h-fit"
            alt=""
          />
          <h3 className="text-xl font-semibold text-green-500">Successful!</h3>
          <p>Congratulations, your application has been sent.</p>
          <DialogClose asChild>
            <Button type="button" className="px-12 rounded-full">
              Okay
            </Button>
          </DialogClose>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default ApplyJob;
