// import { Button } from "@/components/ui/button";
import { IdCard } from "lucide-react";
// import { Link } from "react-router-dom";

import { Application, User } from "@/types";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
type Props = {
  user: User;
};
const AppliedJobs = ({ user }: Props) => {
  return (
    <div>
      <div className="flex items-center">
        <h1 className="flex-grow mb-4 text-xl font-semibold">Applied Jobs</h1>
      </div>
      <div>
        <div className="grid grid-cols-6 px-3 py-2 text-white rounded-lg bg-foreground hover:bg-foreground dark:text-slate-800">
          <div></div>
          <div>Job Title</div>
          <div>Applied Date</div>
          <div>Status</div>
          <div>Agent Info</div>
          {/* <div>Actions</div> */}
        </div>
        {user?.applications?.length ? (
          user.applications.map((application: Application) => (
            <div
              key={application.id}
              className="grid items-center grid-cols-6 px-3 py-2 my-2 rounded-lg dark:border border-slate-700 bg-white/50 dark:bg-background"
            >
              <div className="flex justify-center">
                <img
                  src="/vite.svg"
                  className="w-[60px] h-[60px] object-cover rounded-lg"
                  loading="lazy"
                  alt=""
                />
              </div>
              <div>{application.job.title}</div>
              <div>{application.created_at?.slice(0, 10)}</div>
              <div>{application.status}</div>
              <div>
                <Dialog>
                  <DialogTrigger className="px-2 py-1 text-xl text-white rounded-lg bg-tint-background">
                    <IdCard />
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle></DialogTitle>
                      <DialogDescription className="flex flex-col items-center gap-4">
                        <img
                          src="/vite.svg"
                          className="w-[75px] h-[75px] object-cover rounded-lg"
                          alt=""
                        />
                        <span className="text-lg text-primary">Jack Lucas</span>
                      </DialogDescription>
                    </DialogHeader>
                  </DialogContent>
                </Dialog>
              </div>
              {/* <div>
                <Button
                  asChild
                  className="border bg-background dark:bg-foreground border-background text-slate-800"
                >
                  <Link to={`/job-applications/${application.id}`}>
                    View Details
                  </Link>
                </Button>
              </div> */}
            </div>
          ))
        ) : (
          <div>
            <p className="py-12 text-center">No applications for this user.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppliedJobs;
