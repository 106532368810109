import { Button } from "@/components/ui/button";
import useQuery from "@/hooks/use-query";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { useState } from "react";
import ApiService from "@/services/ApiService";
import { useToast } from "@/hooks/use-toast";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import Error from "@/components/ui/error";

const CvList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { toast } = useToast();
  const {
    data: cvs,
    loading,
    error,
    meta,
  } = useQuery(
    "agent-users",
    { page: searchParams.get("page"), per_page: searchParams.get("per_page") },
    location.search,
  );
  const { data: nationalities, loading: nationalitiesLoading } =
    useQuery(`nationalities`);
  const { data: visas, loading: visaLoading } = useQuery(`visa-statuses`);

  const [user, setUser] = useState({
    name: "",
    email: "",
    nationality_id: 1,
    visa_status_id: 1,
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  if (error) {
    return <Error />;
  }
  const paginate = (page: string | number, perPage: string | number) => {
    setSearchParams({ page: page.toString(), per_page: perPage.toString() });
  };

  const createUser = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    ApiService.post("agent-users", user)
      .then((res) => {
        toast({
          title: res.data?.message,
          description: res.data?.message,
          variant: "success",
        });
        window.location.reload();
      })
      .catch((err) => {
        setErrors(err.response?.data?.errors);
        toast({
          title: err.response.data?.message,
          description: err.response.data?.message,
          variant: "destructive",
        });
      });
  };

  return !loading ? (
    <div>
      <div className="flex items-center justify-between mb-4">
        <h1 className="flex-grow text-xl font-semibold">Your Person List</h1>
        <Dialog>
          <DialogTrigger asChild>
            <Button>Create New User</Button>
          </DialogTrigger>
          <DialogContent className="bg-white lg:min-w-[800px] dark:bg-slate-700">
            <DialogHeader>
              <DialogTitle>Create New User</DialogTitle>
              <DialogDescription></DialogDescription>
              <form
                className="grid grid-cols-1 gap-4 lg:grid-cols-2"
                onSubmit={createUser}
              >
                <div>
                  <Label>Name</Label>
                  <Input
                    name="name"
                    value={user.name}
                    onChange={(e) => setUser({ ...user, name: e.target.value })}
                    placeholder="John Doe"
                    className="w-full mt-1"
                  />
                  {errors.name ? (
                    <p className="mt-1 text-sm text-destructive">
                      {errors.name}
                    </p>
                  ) : null}
                </div>
                <div>
                  <Label>Email</Label>
                  <Input
                    name="email"
                    value={user.email}
                    onChange={(e) =>
                      setUser({ ...user, email: e.target.value })
                    }
                    type="email"
                    placeholder="example@gmail.com"
                    className="w-full mt-1"
                  />
                  {errors.email ? (
                    <p className="mt-1 text-sm text-destructive">
                      {errors.email}
                    </p>
                  ) : null}
                </div>
                <div>
                  <Label htmlFor="nationality_id">Nationality</Label>
                  {!nationalitiesLoading ? (
                    <Select
                      value={user.nationality_id?.toString()}
                      onValueChange={(e) => {
                        setUser({ ...user, nationality_id: Number(e) });
                      }}
                    >
                      <SelectTrigger className="w-full mt-1 ">
                        <SelectValue placeholder="Choose nationality">
                          {
                            nationalities?.find(
                              (nation) => nation.id == user.nationality_id,
                            )?.name
                          }
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        {nationalities?.map((nation) => (
                          <SelectItem key={nation.id} value={nation.id}>
                            {nation.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  ) : (
                    <div className="px-3 py-2 text-sm rounded bg-slate-200">
                      Loading . . .
                    </div>
                  )}
                  {errors.nationality_id ? (
                    <p className="mt-1 text-sm text-destructive">
                      {errors.nationality_id}
                    </p>
                  ) : null}
                </div>
                <div>
                  <Label htmlFor="visa_status_id">Visa Status</Label>
                  {!visaLoading ? (
                    <Select
                      value={user.visa_status_id?.toString()}
                      onValueChange={(e) => {
                        setUser({ ...user, visa_status_id: Number(e) });
                      }}
                    >
                      <SelectTrigger className="w-full mt-1 ">
                        <SelectValue placeholder="Choose Visa Status">
                          {
                            visas?.find(
                              (visa) => visa.id == user?.visa_status_id,
                            )?.name
                          }
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        {visas?.map((visa) => (
                          <SelectItem key={visa.id} value={visa.id}>
                            {visa.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  ) : (
                    <div className="px-3 py-2 text-sm rounded bg-slate-200">
                      Loading . . .
                    </div>
                  )}
                  {errors.visa_status_id ? (
                    <p className="mt-1 text-sm text-destructive">
                      {errors.visa_status_id}
                    </p>
                  ) : null}
                </div>
                <div className="flex justify-end lg:col-span-2">
                  <Button type="submit">Create</Button>
                </div>
              </form>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      </div>
      <div>
        <div className="grid grid-cols-6 p-3 text-white rounded-lg bg-foreground hover:bg-foreground dark:text-slate-800">
          <div>ID</div>
          <div>Name</div>
          <div>Email</div>
          <div>Nationality</div>
          <div>Visa Type</div>
          <div>Actions</div>
        </div>
        {cvs.length ? (
          cvs.map((cv) => (
            <div
              key={cv.id}
              className="grid items-center grid-cols-6 px-3 py-2 my-2 rounded-lg dark:border border-slate-700 bg-white/50 dark:bg-background"
            >
              <div>{cv.id}</div>
              <div>{cv.name}</div>
              <div>{cv.email}</div>
              <div>{cv.nationality?.name}</div>
              <div>{cv.visa_status?.name}</div>
              <div>
                <Button
                  asChild
                  className="border bg-background dark:bg-foreground border-background text-slate-800"
                >
                  <Link to={`/cv-list/${cv.id}`}>View Details</Link>
                </Button>
              </div>
            </div>
          ))
        ) : (
          <div className="flex justify-center py-12">
            <p>No data.</p>
          </div>
        )}
      </div>

      <div className="flex justify-center gap-2 mt-6">
        <Button
          size="icon"
          variant="outline"
          disabled={meta.current_page < 2}
          onClick={() => {
            paginate(meta.current_page - 1, meta.per_page);
          }}
        >
          <ChevronLeft />
        </Button>
        <Button
          size="icon"
          variant="outline"
          disabled={meta.current_page >= meta.last_page}
          onClick={() => {
            paginate(meta.current_page + 1, meta.per_page);
          }}
        >
          <ChevronRight />
        </Button>
      </div>
    </div>
  ) : (
    <div className="p-2">
      <Skeleton className="h-[75px] mb-4" />
      <Skeleton className="h-[75px] mb-4" />
      <Skeleton className="h-[75px] mb-4" />
      <Skeleton className="h-[75px] mb-4" />
      <Skeleton className="h-[75px] mb-4" />
      <Skeleton className="h-[75px] mb-4" />
    </div>
  );
};

export default CvList;
