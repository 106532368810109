import useQuery from "@/hooks/use-query";
import { ChevronDown, ChevronLeft, ChevronRight } from "lucide-react";
import JobCard from "@/components/shared-jobs/JobCard";

import { useLocation, useSearchParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import Error from "@/components/ui/error";

const SavedJobs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const {
    data: jobs,
    loading,
    error,
    meta,
  } = useQuery(
    "saved-jobs",
    { page: searchParams.get("page"), per_page: searchParams.get("per_page") },
    location.search,
  );

  const paginate = (page: string | number, perPage: string | number) => {
    setSearchParams({ page: page.toString(), per_page: perPage.toString() });
  };
  if (error) {
    return <Error />;
  }
  return !loading ? (
    <div>
      <h1 className="flex items-center gap-2 mb-4 text-2xl">
        <span>Saved Jobs</span> <ChevronDown />
      </h1>
      <div>
        {!jobs.length && (
          <p className="py-12 text-center">No data in record.</p>
        )}
        <ul className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
          {jobs.map((job) => (
            <JobCard job={job} key={job.id} />
          ))}
        </ul>
        <div className="flex justify-center gap-2 mt-6">
          <Button
            size="icon"
            variant="outline"
            disabled={meta.current_page < 2}
            onClick={() => {
              paginate(meta.current_page - 1, meta.per_page);
            }}
          >
            <ChevronLeft />
          </Button>
          <Button
            size="icon"
            variant="outline"
            disabled={meta.current_page >= meta.last_page}
            onClick={() => {
              paginate(meta.current_page + 1, meta.per_page);
            }}
          >
            <ChevronRight />
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
      <Skeleton className="h-[270px]" />
      <Skeleton className="h-[270px]" />
      <Skeleton className="h-[270px]" />
      <Skeleton className="h-[270px]" />
      <Skeleton className="h-[270px]" />
      <Skeleton className="h-[270px]" />
    </div>
  );
};

export default SavedJobs;
