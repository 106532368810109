import { useState } from "react";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Button } from "../ui/button";
// import DatePicker from "../ui/date-picker";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
// import { format } from "date-fns";
import useQuery from "@/hooks/use-query";
import { User } from "@/types";
import ApiService from "@/services/ApiService";
import { useToast } from "@/hooks/use-toast";

type Props = {
  user: User;
};
const UserInfo = ({ user: u }: Props) => {
  const { toast } = useToast();
  const [user, setUser] = useState({ ...u });
  const [editing, setEditing] = useState(false);
  // const { data: states, loading: statesLoading } = useQuery(`states`);
  const { data: nationalities, loading: nationalitiesLoading } =
    useQuery(`nationalities`);
  const { data: visas, loading: visaLoading } = useQuery(`visa-statuses`);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const cancelEdit = () => {
    setUser({ ...u });
    setEditing(false);
  };

  // const emitDate = (value: Date) => {
  //   setUser(() => {
  //     return {
  //       ...user,
  //       dob: format(value, "yyyy-MM-dd")
  //     };
  //   });
  // };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    ApiService.put(`cv-forms/${user.id}`, user)
      .then((res) => {
        setLoading(false);
        toast({
          title: res.data?.message,
          description: res.data?.message,
          variant: "success",
        });
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        setErrors(err.response?.data?.errors);
        toast({
          title: err.response.data?.message,
          description: err.response.data?.message,
          variant: "destructive",
        });
      });
  };

  return (
    <div className="gap-8 p-1 bg-white rounded-lg md:flex dark:bg-slate-700 sm:p-4">
      <img
        src="/vite.svg"
        className="max-w-[240px] h-fit shadow-lg p-2 w-full mx-auto sm:w-1/5 rounded-full object-cover"
        alt=""
      />
      <form
        onSubmit={handleSubmit}
        className="grid flex-grow grid-cols-1 gap-4 h-fit md:grid-cols-2"
      >
        <div>
          <Label>Name</Label>
          <Input
            value={user.name}
            disabled={!editing}
            onChange={(e) => setUser({ ...user, name: e.target.value })}
          />
          {errors.name ? (
            <p className="text-destructive">{errors.name}</p>
          ) : null}
        </div>
        <div>
          <Label>Email</Label>
          <Input
            value={user.email}
            disabled={!editing}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          />
          {errors.email ? (
            <p className="text-destructive">{errors.email}</p>
          ) : null}
        </div>
        {/* <div>
          <Label>Gender</Label>
          <Input
            value={user.gender}
            disabled={!editing}
            onChange={(e) => setUser({ ...user, gender: e.target.value })}
          />
        </div> */}
        {/* <div>
          <Label>Date of Birth</Label>
          <br />
          <DatePicker
            disabled={!editing}
            date={new Date(user.dob ? user.dob : "2000-01-01")}
            emitDate={emitDate}
          />
        </div> */}
        {/* <div className="mb-6">
          <Label htmlFor="state_id">State *</Label>
          {!statesLoading ? (
            <Select
              value={user.state_id?.toString()}
              disabled={!editing}
              onValueChange={(e) => {
                setUser({ ...user, state_id: Number(e) });
              }}
            >
              <SelectTrigger className="w-full mt-1 ">
                <SelectValue placeholder="Choose State">
                  {states?.find((s) => s.id == user.state_id)?.name}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                {states?.map((state) => (
                  <SelectItem key={state.id} value={state.id}>
                    {state.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          ) : (
            <div className="px-3 py-2 text-sm rounded bg-slate-200">Loading . . .</div>
          )}
        </div> */}
        <div className="mb-6">
          <Label htmlFor="nationality_id">Nationality</Label>
          {!nationalitiesLoading ? (
            <Select
              value={user.nationality_id?.toString()}
              disabled={!editing}
              onValueChange={(e) => {
                setUser({ ...user, nationality_id: Number(e) });
              }}
            >
              <SelectTrigger className="w-full mt-1 ">
                <SelectValue placeholder="Choose nationality">
                  {
                    nationalities?.find(
                      (nation) => nation.id == user.nationality_id,
                    )?.name
                  }
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                {nationalities?.map((nation) => (
                  <SelectItem key={nation.id} value={nation.id}>
                    {nation.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          ) : (
            <div className="px-3 py-2 text-sm rounded bg-slate-200">
              Loading . . .
            </div>
          )}
          {errors.nationality_id ? (
            <p className="text-destructive">{errors.nationality_id}</p>
          ) : null}
        </div>
        <div className="mb-6">
          <Label htmlFor="visa_status_id">Visa Status</Label>
          {!visaLoading ? (
            <Select
              value={user.visa_status_id?.toString()}
              disabled={!editing}
              onValueChange={(e) => {
                setUser({ ...user, visa_status_id: Number(e) });
              }}
            >
              <SelectTrigger className="w-full mt-1 ">
                <SelectValue placeholder="Choose Visa Status">
                  {visas?.find((visa) => visa.id == user?.visa_status_id)?.name}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                {visas?.map((visa) => (
                  <SelectItem key={visa.id} value={visa.id}>
                    {visa.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          ) : (
            <div className="px-3 py-2 text-sm rounded bg-slate-200">
              Loading . . .
            </div>
          )}
          {errors.visa_status_id ? (
            <p className="text-destructive">{errors.visa_status_id}</p>
          ) : null}
        </div>
        <div className="flex justify-end col-span-2">
          {editing ? (
            <div className="flex items-center gap-4">
              <span
                onClick={cancelEdit}
                className="px-4 py-2 rounded-lg cursor-pointer border-primary"
              >
                Cancel
              </span>
              <Button disabled={loading}>
                {!loading ? "Update" : "Loading"}
              </Button>
            </div>
          ) : (
            <span
              onClick={() => setEditing(true)}
              className="px-4 py-2 text-white rounded-lg cursor-pointer bg-primary"
            >
              Edit Profile
            </span>
          )}
        </div>
      </form>
    </div>
  );
};

export default UserInfo;
