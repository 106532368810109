import {
  BadgeJapaneseYen,
  Banknote,
  Bookmark,
  BriefcaseBusiness,
  ChevronDown,
  ChevronLeft,
  Clock,
  Languages,
  MessageSquareMore,
  Upload,
  UserCheck,
  Users,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import Autoplay from "embla-carousel-autoplay";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import JobCard from "@/components/shared-jobs/JobCard";

import parse from "html-react-parser";
import { Job, Jobs } from "@/types";
import ApplyJob from "@/components/shared-jobs/ApplyJob";
import { Link, useNavigate, useParams } from "react-router-dom";
import useQuery from "@/hooks/use-query";
import { useToast } from "@/hooks/use-toast";
import ApiService from "@/services/ApiService";
import { format } from "date-fns";
import { Skeleton } from "@/components/ui/skeleton";
import Error from "@/components/ui/error";

const jobs: Jobs = [
  {
    id: 1,
    has_shared: true,
    title: "Web Developer",
    address: "Insein, Yangon, Myanmar",
    agent: { id: 2, name: "Google" },
    employment_type: {
      id: 1,
      name: "Full Time",
    },
    jp_language_require: true,
    pay_scale: "Mid",
    min_salary: 100000,
    max_salary: 300000,
    show_pay_by: "monthly",
    created_at: "2024-10-04T16:28:28.000000Z",
  },
  {
    id: 2,
    has_shared: false,
    title: "Web Developer",
    address: "Insein, Yangon, Myanmar",
    agent: { id: 2, name: "Google" },
    employment_type: {
      id: 1,
      name: "Full Time",
    },
    jp_language_require: true,
    pay_scale: "Mid",
    min_salary: 100000,
    max_salary: 300000,
    show_pay_by: "monthly",
    created_at: "2024-10-04T16:28:28.000000Z",
  },
  {
    id: 3,
    has_shared: true,
    title: "Web Developer",
    address: "Insein, Yangon, Myanmar",
    agent: { id: 2, name: "Google" },
    employment_type: {
      id: 1,
      name: "Full Time",
    },
    jp_language_require: true,
    pay_scale: "Mid",
    min_salary: 100000,
    max_salary: 300000,
    show_pay_by: "monthly",
    created_at: "2024-10-04T16:28:28.000000Z",
  },
  {
    id: 4,
    has_shared: true,
    title: "Web Developer",
    address: "Insein, Yangon, Myanmar",
    agent: { id: 2, name: "Google" },
    employment_type: {
      id: 1,
      name: "Full Time",
    },
    jp_language_require: true,
    pay_scale: "Mid",
    min_salary: 100000,
    max_salary: 300000,
    show_pay_by: "monthly",
    created_at: "2024-10-04T16:28:28.000000Z",
  },
  {
    id: 5,
    has_shared: true,
    title: "Web Developer",
    address: "Insein, Yangon, Myanmar",
    agent: { id: 2, name: "Google" },
    employment_type: {
      id: 1,
      name: "Full Time",
    },
    jp_language_require: true,
    pay_scale: "Mid",
    min_salary: 100000,
    max_salary: 300000,
    show_pay_by: "monthly",
    created_at: "2024-10-04T16:28:28.000000Z",
  },
  {
    id: 6,
    has_shared: true,
    title: "Web Developer",
    address: "Insein, Yangon, Myanmar",
    agent: { id: 2, name: "Google" },
    employment_type: {
      id: 1,
      name: "Full Time",
    },
    jp_language_require: true,
    pay_scale: "Mid",
    min_salary: 100000,
    max_salary: 300000,
    show_pay_by: "monthly",
    created_at: "2024-10-04T16:28:28.000000Z",
  },
  {
    id: 7,
    has_shared: true,
    title: "Web Developer",
    address: "Insein, Yangon, Myanmar",
    agent: { id: 2, name: "Google" },
    employment_type: {
      id: 1,
      name: "Full Time",
    },
    jp_language_require: true,
    pay_scale: "Mid",
    min_salary: 100000,
    max_salary: 300000,
    show_pay_by: "monthly",
    created_at: "2024-10-04T16:28:28.000000Z",
  },
];

const SharedJobDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    data: job,
    error,
    loading,
  }: { data: Job; error: unknown; loading: boolean } = useQuery(`jobs/${id}`);
  const { toast } = useToast();

  const saveJob = () => {
    ApiService.post(`jobs/${id}/save`, {})
      .then((res) => {
        toast({
          title: res.data?.message,
          description: res.data?.message,
          variant: "success",
        });
      })
      .catch((err) => {
        toast({
          title: "Error saving job.",
          description: err.response.data?.message,
          variant: "destructive",
        });
      });
  };
  if (error) {
    return <Error />;
  }
  return !loading ? (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
      <div className="relative col-span-2 mt-8 bg-white rounded-lg dark:bg-slate-700">
        <Button
          onClick={() =>
            window.history.length > 2
              ? navigate(-1)
              : navigate("/shared-jobs?page=1&per_page=15")
          }
          size="icon"
          className="absolute top-[-21px] left-[0px] rounded-full"
        >
          <ChevronLeft />
        </Button>
        <div className="z-50 flex flex-wrap gap-4 p-3 pt-6 bg-white rounded-t-lg lg:mx-6 dark:bg-slate-700">
          <div className="flex-grow">
            <h1 className="text-xl font-semibold">{job.title}</h1>
            <h4 className="my-1">{job.agent?.name}</h4>
            <div className="flex items-center gap-2">
              <p className="flex items-center gap-1 px-2 py-1 rounded min-w-fit bg-slate-100 dark:bg-slate-700">
                <Banknote />
                <span>Agent Fees - {job.share_history?.amount_text}</span>
              </p>
              {job.job_type && (
                <p className="px-2 py-1 rounded bg-slate-100 min-w-fit dark:bg-slate-700">
                  {job.job_type?.name}
                </p>
              )}
              {job.sub_category && (
                <p className="px-2 py-1 rounded bg-slate-100 min-w-fit dark:bg-slate-700">
                  {job.sub_category?.name}
                </p>
              )}
            </div>
          </div>
          <div className="flex w-full md:w-fit md:max-w-[250px] flex-wrap justify-end gap-2">
            <p className="flex items-center w-full gap-1">
              <Clock />
              <span>{format(job.created_at, "MMM dd , yyyy")}</span>
            </p>
            <p className="flex items-center w-full gap-2 text-destructive">
              <Clock />
              <span>
                Expired Date -{" "}
                {job.job_expired_date
                  ? `${format(job.job_expired_date, "MMM dd ,yyyy")}`
                  : "None"}
              </span>
            </p>
            <Button size="icon" variant="secondary">
              <MessageSquareMore />
            </Button>
            <Button
              onClick={saveJob}
              disabled={job.is_saved}
              size="icon"
              variant="secondary"
              className={`${job.is_saved ? "text-primary" : ""}`}
            >
              <Bookmark />
            </Button>
            <Dialog>
              <DialogTrigger asChild>
                <Button className="flex items-center w-full gap-1">
                  <Upload />
                  <span>Apply Now</span>
                </Button>
              </DialogTrigger>
              <DialogContent className="xl:min-w-[1280px] bg-white dark:bg-slate-700 min-w-[90%] min-h-[600px] ">
                <DialogHeader>
                  <DialogTitle>
                    <span className="text-sm">Apply Job</span>
                  </DialogTitle>
                  <DialogDescription></DialogDescription>
                  <ApplyJob jobId={job.id} />
                </DialogHeader>
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <div className="grid gap-2 p-3 border rounded-lg md:m-6 sm:grid-cols-2">
          <div className="py-2 text-sm h-fit">
            <p className="flex items-center gap-2">
              <BriefcaseBusiness />
              <span className="text-lg">Employee Type</span>
            </p>
            <span className="ml-8">{job.employment_type?.name}</span>
          </div>
          <div className="py-2 text-sm h-fit">
            <p className="flex items-center gap-2">
              <BadgeJapaneseYen />
              <span className="text-lg">Basic Salary</span>
            </p>
            <span className="ml-8">{job.salary_range}</span>
          </div>
          <div className="py-2 text-sm h-fit">
            <p className="flex items-center gap-2">
              <BadgeJapaneseYen />
              <span className="text-lg">Yearly Salary</span>
            </p>
            <span className="ml-8">{job.yearly_salary}¥</span>
          </div>
          <div className="py-2 text-sm h-fit">
            <p className="flex items-center gap-2">
              <Languages />
              <span className="text-lg">Japanese Language</span>
            </p>
            <span className="ml-8">
              {job?.japan_level?.name ? job.japan_level.name : "-"}
            </span>
          </div>
          <div className="py-2 text-sm h-fit">
            <p className="flex items-center gap-2">
              <UserCheck />
              <span className="text-lg">Foreign Apply</span>
            </p>
            <span className="ml-8">{job?.foreign_apply ? "Yes" : "No"}</span>
          </div>
          <div className="py-2 text-sm h-fit">
            <p className="flex items-center gap-2">
              <Users />
              <span className="text-lg">Number of People to Hire</span>
            </p>
            <span className="ml-8">
              {job?.anyone ? `${job.anyone} People` : "-"}
            </span>
          </div>
        </div>
        <div className="p-3 max-h-[720px] overflow-y-scroll hide-scroll hide-scroll dark:bg-slate-700">
          <h3 className="text-lg">Description</h3>
          <div className="mb-6 paragraph">
            {job.description ? parse(job.description) : ""}
          </div>

          <h3 className="text-lg">Requirements</h3>
          <div className="mb-6 paragraph">
            {job.requirements ? parse(job.requirements) : "-"}
          </div>

          <h3 className="text-lg">Address</h3>
          <p className="mb-6">
            • {job.postal_code} {job.state?.name} {job.address}
          </p>

          <div className="mb-6">
            <h3 className="text-lg">Nationalities</h3>
            <ul className="pl-8 list-disc">
              {job.nationalities?.map((nation) => (
                <li key={nation.id}>{nation.name}</li>
              ))}
            </ul>
          </div>

          <h3 className="text-lg">Age</h3>
          <p className="mb-6">
            •{" "}
            {job.min_age || job.max_age
              ? `${job.min_age} yrs to ${job.max_age} yrs`
              : "Not set"}
          </p>

          <h3 className="text-lg">Bonus</h3>
          <p>• {job.bonus ? "Yes" : "No"}</p>
          <div className="mb-6 paragraph">
            {job.bonus_explain ? parse(job.bonus_explain) : ""}
          </div>

          <h3 className="text-lg">House Support</h3>
          <p>• {job.housing_support ? "Yes" : "No"}</p>
          <div className="mb-6 paragraph">
            {job.housing_explain ? parse(job.housing_explain) : ""}
          </div>

          <h3 className="text-lg">Yearly Salary</h3>
          <p className="mb-6">• {job.yearly_salary} ¥</p>

          <h3 className="text-lg">Salary Tax</h3>
          <p>• {job.tax}</p>
          <div className="mb-4 paragraph">
            {job.tax_explain ? parse(job.tax_explain) : ""}
          </div>
          <div className="mb-6 paragraph">
            {job.tax_left_explain ? parse(job.tax_left_explain) : ""}
          </div>

          <h3 className="text-lg">Working Hour</h3>
          <p className="mb-6">
            • {job.working_hour} hours / {job.working_hour_per}
          </p>

          <h3 className="text-lg">Overtime</h3>
          <p className="mb-6">
            •{" "}
            {job.overtime ? `${job.overtime_per_month} hours / monthly` : "No"}
          </p>

          <h3 className="text-lg">Trail Period</h3>
          <p>• {job.trial_deadline ? "Yes" : "No"}</p>
          <div className="mb-6 paragraph">
            {job.trial_explain ? parse(job.trial_explain) : ""}
          </div>

          <h3 className="text-lg">Off Days</h3>
          <p>• {job.off_days_per_year} days / yearly</p>
          <div className="mb-6 paragraph">
            {job.off_days_explain ? parse(job.off_days_explain) : ""}
          </div>

          <h3 className="text-lg">Benefits</h3>
          <ul className="pl-8 mb-6 list-disc">
            {job.benefits && job.benefits.length
              ? job.benefits.map((b) => <li key={b.id}>{b.name}</li>)
              : "-"}
          </ul>

          <h3 className="text-lg">Advantages of the Job</h3>
          <div className="flex items-center mb-6 paragraph">
            {job.advantage ? parse(job.advantage) : ""}
          </div>

          <h3 className="text-lg">Job Category</h3>
          <p className="mb-6">• {job.sub_category?.name}</p>

          <h3 className="text-lg">Job Expired Date</h3>
          <p className="mb-6">• {job.job_expired_date}</p>

          <div className="mb-6">
            <h3 className="text-lg">Tags</h3>
            <ul className="pl-8 list-disc">
              {job.tags?.map((tag) => <li key={tag.id}>{tag.name}</li>)}
            </ul>
          </div>
        </div>
      </div>
      <div className="col-span-1 mt-4 sm:mt-0">
        <div className="mt-4 rounded-lg">
          <h3 className="flex items-center gap-2 text-lg">
            <span>Similar Jobs</span>
            <ChevronDown />
          </h3>
          <Carousel
            opts={{
              align: "start",
              loop: true,
            }}
            plugins={[
              Autoplay({
                delay: 4000,
              }),
            ]}
            orientation="vertical"
            className="w-full mt-16"
          >
            <CarouselContent className="lg:h-[720px]">
              {jobs.map((job) => (
                <CarouselItem key={job.id} className="md:basis-1/5 basis-1/3">
                  <Link to={`/shared-jobs/${job.id}`}>
                    <JobCard job={job} />
                  </Link>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        </div>
      </div>
    </div>
  ) : (
    <div className="grid grid-cols-1 gap-4 p-2 lg:grid-cols-3">
      <div className="lg:col-span-2">
        <Skeleton className="h-[120px] mb-6" />
        <Skeleton className="h-[120px] mb-6" />
        <Skeleton className="h-[400px] mb-6" />
      </div>
      <div>
        <Skeleton className="h-[270px] mb-6" />
        <Skeleton className="h-[270px] mb-6" />
        <Skeleton className="h-[270px] mb-6" />
        <Skeleton className="h-[270px] mb-6" />
      </div>
    </div>
  );
};

export default SharedJobDetails;
