import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

import { ChevronLeft } from "lucide-react";
import { Job } from "@/types";
import useQuery from "@/hooks/use-query";
import { Checkbox } from "@/components/ui/checkbox";
import { Textarea } from "@/components/ui/textarea";

type Props = {
  setTab: React.Dispatch<React.SetStateAction<string>>;
  job: Job;
  setJob: React.Dispatch<React.SetStateAction<Job>>;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>, nextTab: string) => void;
};

const StepFive = ({ setTab, job, setJob, handleSubmit }: Props) => {
  const { data: nationalities, loading: nationLoading } =
    useQuery("nationalities");
  const { data: tags, loading: tagLoading } = useQuery("tag-groups");
  const { data: benefits, loading: benefitsLoading } = useQuery("benefits");

  const checkNations = (id: number, checked: boolean | string) => {
    if (checked) {
      setJob({
        ...job,
        nationality_ids: [...job.nationality_ids, id],
      });
    } else {
      setJob({
        ...job,
        nationality_ids: job.nationality_ids.filter((n) => n !== id),
      });
    }
  };
  const checkTags = (id: number, checked: boolean | string) => {
    if (checked) {
      setJob({
        ...job,
        tag_ids: [...job.tag_ids, id],
      });
    } else {
      setJob({
        ...job,
        tag_ids: job.tag_ids.filter((t) => t !== id),
      });
    }
  };
  const checkBenefits = (id: number, checked: boolean | string) => {
    if (checked) {
      setJob({
        ...job,
        benefit_ids: [...job.benefit_ids, id],
      });
    } else {
      setJob({
        ...job,
        benefit_ids: job.benefit_ids.filter((b) => b !== id),
      });
    }
  };

  return (
    <form
      onSubmit={(e) => handleSubmit(e, "2")}
      className="max-h-[80vh] hide-scroll overflow-y-auto"
    >
      <div className="flex flex-wrap gap-6 mb-6">
        <Label htmlFor="title" className="w-full">Benefits.</Label>
        {!benefitsLoading ? (
          benefits.map((benefit: { name: string; id: number }) => (
            <div key={benefit.id} className="flex items-center gap-1">
              <Checkbox
                onCheckedChange={(e) => checkBenefits(benefit.id, e)}
                checked={job.benefit_ids?.includes(benefit.id)}
                key={benefit.id}
              />
              <Label>{benefit.name}</Label>
            </div>
          ))
        ) : (
          <p>Loading</p>
        )}
        {job.errors?.benefits && (
          <span className="text-destructive">{job.errors.benefits}</span>
        )}
      </div>
      <div className="mb-6">
        <Label htmlFor="title">Explin the advantages of the job.</Label>
        <Textarea
          value={job.advantage}
          onChange={(e) => {
            setJob({ ...job, advantage: e.target.value });
          }}
          className="w-full mt-1 "
          rows={5}
          placeholder="Explain advantages of the job."
        />
        {job.errors?.advantage && (
          <span className="text-destructive">{job.errors.advantage}</span>
        )}
      </div>
      <Label className="text-lg">Nationalities</Label>
      <div className="flex flex-wrap gap-6 mt-2 mb-6">
        {!nationLoading ? (
          nationalities.map((nation: { name: string; id: number }) => (
            <div key={nation.id} className="flex items-center gap-1">
              <Checkbox
                onCheckedChange={(e) => checkNations(nation.id, e)}
                checked={job.nationality_ids?.includes(nation.id)}
                key={nation.id}
              />
              <Label>{nation.name}</Label>
            </div>
          ))
        ) : (
          <p>Loading</p>
        )}
      </div>
      {job.errors && job.errors["nationality_ids.0"] && (
        <p className="text-destructive">{job.errors["nationality_ids.0"]}</p>
      )}
      {job.errors && job.errors["nationality_ids"] && (
        <p className="text-destructive">{job.errors["nationality_ids"]}</p>
      )}
      <Label className="text-lg">Tags</Label>
      <div className="mt-2 mb-6">
        {!tagLoading ? (
          tags.map(
            (tagGp: {
              name: string;
              tags: Array<{ name: string; id: number }>;
            }) => (
              <div
                className="flex flex-wrap mb-4 gap-x-6 gap-y-2"
                key={tagGp.name}
              >
                <h4 className="w-full text-lg">{tagGp.name}</h4>
                {tagGp.tags.map((tag: { name: string; id: number }) => (
                  <div key={tag.id} className="flex items-center gap-1">
                    <Checkbox
                      onCheckedChange={(e) => checkTags(tag.id, e)}
                      checked={job.tag_ids?.includes(tag.id)}
                      key={tag.id}
                    />
                    <Label>{tag.name}</Label>
                  </div>
                ))}
              </div>
            ),
          )
        ) : (
          <p>Loading</p>
        )}
      </div>
      {job.errors && job.errors["tag_ids.0"] && (
        <p className="text-destructive">{job.errors["tag_ids.0"]}</p>
      )}
      {job.errors && job.errors["tag_ids"] && (
        <p className="text-destructive">{job.errors["tag_ids"]}</p>
      )}
      <div className="mb-6">
        <p>Publish *</p>
        <RadioGroup
          value={job.publish ? "1" : "0"}
          onValueChange={(e) => setJob({ ...job, publish: e == "1" })}
          defaultValue={job.publish ? "1" : "0"}
          className="flex gap-4 mt-2"
        >
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="1" id="on" />
            <Label htmlFor="on">ON</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="0" id="off" />
            <Label htmlFor="off">OFF</Label>
          </div>
        </RadioGroup>
        {job.errors?.publish && (
          <span className="text-destructive">{job.errors.publish}</span>
        )}
      </div>
      <div className="flex justify-between">
        <span
          onClick={() => setTab("4")}
          className="flex items-center gap-2 px-6 py-2 border rounded-full cursor-pointer"
        >
          <ChevronLeft className="w-5 h-5" />
          <span>Previous</span>
        </span>
        {/* <Button className="flex items-center gap-2 px-6 rounded-full">
          <span>Next</span>
          <ChevronRight className="w-5 h-5" />
        </Button> */}
      </div>
    </form>
  );
};

export default StepFive;
