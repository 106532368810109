import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { CvForm } from "@/types";
import { useState } from "react";
import { ArrowLeft, Ellipsis, Upload } from "lucide-react";
import ApiService from "@/services/ApiService";
import { useToast } from "@/hooks/use-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import FormImage from "@/components/cv-list/FormImage";
import FormBasics from "@/components/cv-list/FormBasics";
import FormExperiences from "@/components/cv-list/FormExperiences";
import FormEducation from "@/components/cv-list/FormEducation";
import FormCertificates from "@/components/cv-list/FormCertificates";
import FormSkills from "@/components/cv-list/FormSkills";
import useQuery from "@/hooks/use-query";

const EditCv = () => {
  const { id: userId, cv: formId } = useParams();
  const data = useQuery(`cv-forms/${formId}`);
  console.log(data);
  const navigate = useNavigate();
  const { toast } = useToast();
  const [tab, setTab] = useState("image");
  const [cv, setCv] = useState<CvForm>({
    name: "",
    image_id: 11,
    name_japan: "",
    label: "",
    occupation: "",
    gender: "male",
    age: 1,
    dob: "",
    nationality_id: 1,
    martial_status_id: 2,
    phone_no: "",
    email: "",
    facebook_name: "",
    facebook_profile_link: "",
    postal_code: "",
    state_id: 1,
    address: "",
    visa_status_id: 1,
    visa_deadline_date: "",
    arrival_date: "",
    japan_level_id: 1,
    driver_license_status_id: 1,
    other_personal_staff: "",
    work_experiences: [
      {
        company_name: "",
        position: "",
        start_date: "",
        end_date: "",
        why_quit: "",
      },
    ],
    hobbies: [""],
    schools: [
      {
        name: "",
        major: "",
        from: new Date().toString(),
        to: new Date().toString(),
        school_status_id: 1,
      },
    ],
    certificates: [
      {
        name: "",
        year: 2024,
        month: 1,
        date: new Date(),
      },
    ],
    skills: [
      {
        name: "",
      },
    ],
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const upladCv = () => {
    setLoading(true);
    const obj = cv;
    ApiService.put(`cv-forms/${formId}`, obj)
      .then((res) => {
        setLoading(false);
        toast({
          title: res.data?.message,
          description: res.data?.message,
          variant: "success",
        });
        navigate(`/cv-list/${userId}?tab=forms`);
      })
      .catch((err) => {
        setLoading(false);
        setErrors(err.response?.data?.errors);
        toast({
          title: err.response.data?.message,
          description: err.response.data?.message,
          variant: "destructive",
        });
      });
  };
  return (
    <div className="p-2 bg-white rounded-lg dark:bg-slate-800">
      <div className="flex items-center gap-2">
        <Button size="icon" variant="ghost" asChild>
          <Link to={`/cv-list/${userId}?tab=forms`}>
            <ArrowLeft />
          </Link>
        </Button>
        <h1 className="text-2xl">Edit Cv Form</h1>
      </div>
      <Tabs value={tab} defaultValue="account">
        <TabsList className="w-full bg-transparent">
          <TabsTrigger onClick={() => setTab("image")} value="image">
            Image
          </TabsTrigger>
          <TabsTrigger onClick={() => setTab("basics")} value="basics">
            Basics
          </TabsTrigger>
          <TabsTrigger onClick={() => setTab("exp")} value="exp">
            Experiences
          </TabsTrigger>
          <TabsTrigger onClick={() => setTab("edu")} value="edu">
            Education
          </TabsTrigger>
          <TabsTrigger
            onClick={() => setTab("certificates")}
            value="certificates"
          >
            Certificates
          </TabsTrigger>
          <TabsTrigger onClick={() => setTab("skills")} value="skills">
            Skills
          </TabsTrigger>
        </TabsList>
        <TabsContent value="image">
          <FormImage errors={errors} setTab={setTab} cv={cv} setCv={setCv} />
        </TabsContent>
        <TabsContent value="basics">
          <FormBasics errors={errors} setTab={setTab} cv={cv} setCv={setCv} />
        </TabsContent>
        <TabsContent value="exp">
          <FormExperiences
            errors={errors}
            setTab={setTab}
            cv={cv}
            setCv={setCv}
          />
        </TabsContent>
        <TabsContent value="edu">
          <FormEducation
            errors={errors}
            setTab={setTab}
            cv={cv}
            setCv={setCv}
          />
        </TabsContent>
        <TabsContent value="certificates">
          <FormCertificates
            errors={errors}
            setTab={setTab}
            cv={cv}
            setCv={setCv}
          />
        </TabsContent>
        <TabsContent value="skills">
          <FormSkills errors={errors} setTab={setTab} cv={cv} setCv={setCv} />
        </TabsContent>
      </Tabs>
      <div className="flex justify-center my-4">
        <Button
          onClick={upladCv}
          disabled={loading}
          className="flex items-center w-full gap-2 md:w-1/2"
        >
          {!loading ? <Upload /> : <Ellipsis />}
          {!loading ? <span>Upload Cv Now</span> : <span>Loading</span>}
        </Button>
      </div>
    </div>
  );
};

export default EditCv;
