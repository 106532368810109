import { BadgeCheck, ChevronRight, Plus } from "lucide-react";
import { Button } from "../ui/button";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import useQuery from "@/hooks/use-query";
import { Cv, CvForm } from "@/types";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { Link } from "react-router-dom";
import ApiService from "@/services/ApiService";
import { useToast } from "@/hooks/use-toast";

type Props = {
  user: { [key: string]: unknown };
  selectCv: (type: "file" | "form", cv: Cv | CvForm) => void;
  setTab: React.Dispatch<
    React.SetStateAction<"list" | "success" | "cv" | "apply">
  >;
  selectedCv: Cv | null;
  selectedForm: CvForm | null;
  jobId: number | string;
};

const UserDetails = ({
  user: u,
  selectCv,
  setTab,
  selectedCv,
  selectedForm,
  jobId,
}: Props) => {
  const { toast } = useToast();
  const { data: user, loading, error } = useQuery(`agent-users/${u?.id}`);
  const [cvFiles, setCvFiles] = useState([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const focusInput = () => {
    console.log("first");
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : undefined;
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.set("file", file);
    ApiService.post(`agent-users/${user.id}/upload`, formData, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        toast({
          title: "Uploaded cv file.",
          variant: "success",
        });
        console.log(res);
        const obj = {
          id: res.data?.data?.id,
          url: res.data?.data?.url,
        };
        setCvFiles([obj, ...cvFiles]);
        selectCv("file", obj);
      })
      .catch((err) => {
        toast({
          title: "Error uploading cv file.",
          description: err.response?.data?.message,
          variant: "destructive",
        });
      });
  };
  useEffect(() => {
    if (user?.cv_forms?.length) {
      selectCv("form", user?.cv_forms[0]);
    }
  }, [user, selectCv]);
  useEffect(() => {
    if (user && user.cv_files) {
      setCvFiles(user.cv_files);
    }
  }, [setCvFiles, user]);

  if (error) {
    return "error.";
  }

  return !loading ? (
    <div className="w-full max-h-[600px] overflow-y-scroll hide-scroll h-full relative">
      <input
        onChange={uploadFile}
        ref={inputRef}
        type="file"
        className="hidden"
      />
      <h2 className="text-destructive">Choose one cv file or one cv form</h2>
      <div className="hidden grid-cols-2 lg:grid">
        <ul className="grid grid-cols-2 gap-2 pb-8 pr-4 md:gap-4 h-fit">
          <div className="flex items-center justify-between col-span-2">
            <h2 className="text-lg font-[500]">Created Cv Forms</h2>
            <Button asChild size="icon" variant="secondary">
              <Link
                onClick={() =>
                  window.sessionStorage.setItem("job", jobId.toString())
                }
                to={`/cv-list/${user.id}/cv-forms/create`}
              >
                <Plus />
              </Link>
            </Button>
          </div>
          {user?.cv_forms?.map((cv: CvForm) => (
            <li
              onClick={() => selectCv("form", cv)}
              key={cv.id}
              className={`shadow relative p-2 border rounded-lg cursor-pointer ${
                selectedForm?.id == cv.id ? "border-green-500" : ""
              }`}
            >
              <h3 className="mb-2 text-lg font-semibold">{cv.label}</h3>
              <p> - {cv.occupation}</p>
              <p> - {cv.name}</p>
              <p> - {cv.name_japan}</p>
              <p> - {cv.age}</p>
              <p> - {cv.dob}</p>
              <p> - {cv.gender}</p>
              <p> - {cv.email}</p>
              <p> - {cv.phone_no}</p>
              {selectedForm?.id == cv.id && (
                <BadgeCheck className="absolute text-green-500 right-4 bottom-4" />
              )}
            </li>
          ))}
        </ul>
        <ul className="grid grid-cols-2 gap-2 pb-8 pl-4 border-l h-fit md:gap-4">
          <div className="flex items-center justify-between col-span-2 h-fit">
            <h2 className="text-lg font-[500]">Uploaded Cv Files</h2>
            <Button onClick={focusInput} size="icon" variant="secondary">
              <Plus />
            </Button>
          </div>
          {cvFiles !== null &&
            cvFiles?.map((cv: Cv) => (
              <li
                onClick={() => selectCv("file", cv)}
                key={cv.id}
                className={`shadow relative p-2 border rounded-lg cursor-pointer ${
                  selectedCv?.id == cv.id ? "border-green-500" : ""
                }`}
              >
                <div
                  onClick={() => selectCv("file", cv)}
                  className="absolute top-0 left-0 w-full h-full"
                ></div>
                <iframe
                  key={cv.id}
                  src={cv.url}
                  width="100%"
                  height="240px"
                ></iframe>
                {selectedCv?.id == cv.id && (
                  <BadgeCheck className="absolute text-green-500 right-4 bottom-4" />
                )}
              </li>
            ))}
        </ul>
      </div>
      <Tabs defaultValue="forms" className="block w-full mt-4 lg:hidden">
        <TabsList className="w-full bg-transparent">
          <TabsTrigger value="forms">Cv Forms</TabsTrigger>
          <TabsTrigger value="files">Cv Files</TabsTrigger>
        </TabsList>
        <TabsContent value="forms">
          <ul className="grid grid-cols-2 gap-2 pb-8 pr-4 md:gap-4 h-fit">
            <div className="flex items-center justify-between col-span-2">
              <h2 className="text-lg font-[500]">Created Cv Forms</h2>
              <Button size="icon" variant="secondary">
                <Link
                  onClick={() =>
                    window.sessionStorage.setItem("job", jobId.toString())
                  }
                  to={`/cv-list/${user.id}?tab=forms`}
                >
                  <Plus />
                </Link>
              </Button>
            </div>
            {user?.cv_forms?.map((cv: CvForm) => (
              <li
                onClick={() => selectCv("form", cv)}
                key={cv.id}
                className={`shadow relative p-2 border rounded-lg cursor-pointer ${
                  selectedForm?.id == cv.id ? "border-green-500" : ""
                }`}
              >
                <h3 className="mb-2 text-lg font-semibold">{cv.label}</h3>
                <p> - {cv.occupation}</p>
                <p> - {cv.name}</p>
                <p> - {cv.name_japan}</p>
                <p> - {cv.age}</p>
                <p> - {cv.dob}</p>
                <p> - {cv.gender}</p>
                <p> - {cv.email}</p>
                <p> - {cv.phone_no}</p>
                {selectedForm?.id == cv.id && (
                  <BadgeCheck className="absolute text-green-500 right-4 bottom-4" />
                )}
              </li>
            ))}
          </ul>
        </TabsContent>
        <TabsContent value="files">
          <ul className="grid grid-cols-2 gap-2 pb-8 lg:border-l lg:pl-4 md:gap-4">
            <div className="flex items-center justify-between col-span-2">
              <h2 className="text-lg font-[500]">Uploaded Cv files</h2>
              <Button onClick={focusInput} size="icon" variant="secondary">
                <Plus />
              </Button>
            </div>
            {cvFiles !== null &&
              cvFiles?.map((cv: Cv) => (
                <li
                  onClick={() => selectCv("file", cv)}
                  key={cv.id}
                  className={`shadow relative p-2 border rounded-lg cursor-pointer ${
                    selectedCv?.id == cv.id ? "border-green-500" : ""
                  }`}
                >
                  <div
                    onClick={() => selectCv("file", cv)}
                    className="absolute top-0 left-0 w-full h-full"
                  ></div>
                  <iframe
                    key={cv.id}
                    src={cv.url}
                    width="100%"
                    height="240px"
                  ></iframe>
                  {selectedCv?.id == cv.id && (
                    <BadgeCheck className="absolute text-green-500 right-4 bottom-4" />
                  )}
                </li>
              ))}
          </ul>
        </TabsContent>
      </Tabs>
      <div className="fixed flex justify-end w-full gap-2 px-2 right-6 bottom-6">
        <Button
          onClick={() => setTab("list")}
          variant="outline"
          className="bg-transparent rounded-full"
        >
          Back
        </Button>
        <Button
          className="flex items-center gap-2 rounded-full"
          onClick={() => setTab("apply")}
        >
          <span>Next</span>
          <ChevronRight />
        </Button>
      </div>
    </div>
  ) : (
    <p className="py-8 text-center">loading . . .</p>
  );
};

export default UserDetails;
