import { Button } from "@/components/ui/button";
import { CvForm } from "@/types";
import { ChevronRight, Ellipsis, Upload } from "lucide-react";
import { useRef, useState } from "react";
import { useToast } from "@/hooks/use-toast";
import ApiService from "@/services/ApiService";

type Props = {
  setTab: React.Dispatch<React.SetStateAction<string>>;
  cv: CvForm;
  setCv: React.Dispatch<React.SetStateAction<CvForm>>;
  errors: {
    [key: string]: string;
  };
};
const FormImage = ({ setTab, cv, setCv, errors }: Props) => {
  const { toast } = useToast();
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  const uploadCv = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const file = e.target?.files ? e.target.files[0] : "";
    const formData = new FormData();
    formData.set("file", file);
    ApiService.post(`images`, formData, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        setCv({ ...cv, image: res.data.data, image_id: res.data.data?.id });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: err.response.data?.message,
          description: err.response.data?.message,
          variant: "destructive",
        });
      });
  };
  return (
    <div>
      <h1 className="mb-4 text-xl">Uplod Cv Image</h1>
      {cv.image_id && cv.image ? (
        <img
          src={cv.image.url}
          alt="cv profile"
          className="w-full shadow max-w-[360px] h-fit mx-auto rounded-lg mb-4"
        />
      ) : null}
      <div className="flex flex-wrap justify-center">
        <Button
          disabled={loading}
          onClick={focusInput}
          size="lg"
          variant="secondary"
          className="flex flex-col items-center w-full gap-2 py-6 text-lg md:w-1/2 h-fit"
        >
          {!loading ? <Upload /> : <Ellipsis />}
          <span>Upload cv profile image</span>
        </Button>
        {errors.image_id ? (
          <p className="w-full mt-2 text-center text-destructive">
            {errors.image_id}
          </p>
        ) : null}
        <input
          ref={inputRef}
          onChange={uploadCv}
          type="file"
          className="hidden"
        />
      </div>
      <div className="flex justify-end mt-8">
        <Button
          disabled={loading}
          onClick={() => setTab("basics")}
          className="flex items-center gap-1 bg-transparent rounded-full"
          variant="outline"
        >
          <span>Next</span>
          <ChevronRight />
        </Button>
      </div>
    </div>
  );
};

export default FormImage;
