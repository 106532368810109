import { Button } from "@/components/ui/button";
import { Job } from "@/types";
import {
  ArrowRight,
  BadgeJapaneseYen,
  Banknote,
  Bookmark,
  BriefcaseBusiness,
  ChevronDown,
  Clock,
  Edit,
  Languages,
  Share2,
  SquareActivity,
  UserCheck,
  UserRoundCheck,
  Users
} from "lucide-react";
import parse from "html-react-parser";
import ApiService from "@/services/ApiService";
import { useToast } from "@/hooks/use-toast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from "@/components/ui/dialog";
import { useState } from "react";
import { Label } from "@/components/ui/label";
import {
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Select
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Link } from "react-router-dom";
import useQuery from "@/hooks/use-query";
import ConfirmDialog from "@/components/ui/confirm-dialog";
import { format } from "date-fns";
import { Switch } from "@/components/ui/switch";

type Props = {
  job: Job;
};

const JobInfo = ({ job }: Props) => {
  const { toast } = useToast();
  const {
    data: reasons,
    loading: reasonsLoading
  }: { data: Array<{ id: number; description: string }>; loading: boolean } =
    useQuery("unpublished-reasons");
  const [shareData, setShareData] = useState({ amount: 0, pay_type: "fixed" });
  const [shareDialog, setShareDialog] = useState(false);
  const [publishDialog, setPublishDialog] = useState(false);
  const [publishData, setPublishData] = useState({
    unpublished_reason_id: "1"
  });
  const [confirm, setConfirm] = useState(false);
  const [unshareConfirm, setUnshareConfirm] = useState(false);
  const shareJob = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    ApiService.post(`jobs/${job.id}/share`, shareData)
      .then((res) => {
        toast({
          title: res.data.message,
          description: res.data.message,
          variant: "success"
        });
        setShareDialog(false);
        window.location.reload();
      })
      .catch((err) => {
        toast({
          title: err.response.data?.message,
          description: err.response.data?.message,
          variant: "destructive"
        });
      });
  };
  const removeShare = () => {
    ApiService.post(`jobs/${job.id}/remove-share`, {})
      .then((res) => {
        toast({
          title: res.data.message,
          description: res.data.message,
          variant: "success"
        });
        window.location.reload();
      })
      .catch((err) => {
        toast({
          title: err.response.data?.message,
          description: err.response.data?.message,
          variant: "destructive"
        });
      });
  };
  const unpublishJob = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    ApiService.post(`jobs/${job.id}/unpublish`, publishData)
      .then((res) => {
        toast({
          title: res.data.message,
          description: res.data.message,
          variant: "success"
        });
        setPublishDialog(false);
        window.location.reload();
      })
      .catch((err) => {
        toast({
          title: err.response.data?.message,
          description: err.response.data?.message,
          variant: "destructive"
        });
      });
  };
  const publishJob = () => {
    ApiService.post(`jobs/${job.id}/publish`, {})
      .then((res) => {
        toast({
          title: res.data.message,
          description: res.data.message,
          variant: "success"
        });
        window.location.reload();
      })
      .catch((err) => {
        toast({
          title: err.response.data?.message,
          description: err.response.data?.message,
          variant: "destructive"
        });
      });
  };
  return (
    <div>
      <Dialog open={shareDialog} onOpenChange={setShareDialog}>
        <DialogContent className="bg-white lg:min-w-[800px] dark:bg-slate-700">
          <DialogHeader>
            <DialogTitle>Share Job</DialogTitle>
            <DialogDescription>Share job to agents</DialogDescription>
            <form
              className="grid grid-cols-1 gap-4 lg:grid-cols-2"
              onSubmit={shareJob}
            >
              <div>
                <Label htmlFor="pay_type">Pay Type</Label>
                <Select
                  value={shareData.pay_type}
                  onValueChange={(e) => {
                    setShareData({ ...shareData, pay_type: e });
                  }}
                >
                  <SelectTrigger className="w-full mt-1">
                    <SelectValue placeholder="fixed" />
                  </SelectTrigger>
                  <SelectContent defaultValue="fixed">
                    <SelectItem value="fixed">Fixed</SelectItem>
                    <SelectItem value="percentage">Percentage</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div>
                <Label htmlFor="amount">
                  Amount {shareData.pay_type == "percentage" ? "(%)" : null}
                </Label>
                <Input
                  value={shareData.amount.toString()}
                  type="number"
                  className="w-full mt-1"
                  onChange={(e) =>
                    setShareData({
                      ...shareData,
                      amount: Number(e.target.value)
                    })
                  }
                />
              </div>
              <div className="flex justify-end lg:col-span-2">
                <Button type="submit">Share Job</Button>
              </div>
            </form>
          </DialogHeader>
        </DialogContent>
      </Dialog>
      <Dialog open={publishDialog} onOpenChange={setPublishDialog}>
        <DialogContent className="bg-white lg:min-w-[800px] dark:bg-slate-700">
          <DialogHeader>
            <DialogTitle>Unpublish Job</DialogTitle>
            <DialogDescription>
              Unpublish Job to close applications
            </DialogDescription>
            <form className="grid grid-cols-1 gap-4" onSubmit={unpublishJob}>
              <div>
                <Label>Unpublish Reason</Label>
                <Select
                  value={shareData.pay_type}
                  onValueChange={(e) => {
                    setPublishData({
                      ...publishData,
                      unpublished_reason_id: e
                    });
                  }}
                >
                  <SelectTrigger className="w-full mt-1">
                    <SelectValue placeholder="Choose a reason">
                      {
                        reasons?.find(
                          (reason) =>
                            reason.id.toString() ===
                            publishData.unpublished_reason_id
                        )?.description
                      }
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent defaultValue="1">
                    {reasonsLoading ? (
                      <SelectItem value="0">Loading</SelectItem>
                    ) : (
                      reasons.map((reason) => (
                        <SelectItem
                          key={reason.id}
                          value={reason.id.toString()}
                        >
                          {reason.description}
                        </SelectItem>
                      ))
                    )}
                  </SelectContent>
                </Select>
              </div>
              <div className="flex justify-end">
                <Button type="submit" className="bg-destructive">
                  Unpublish Job
                </Button>
              </div>
            </form>
          </DialogHeader>
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        open={confirm}
        setOpen={setConfirm}
        action={publishJob}
        header={"Publish Job"}
        message={"Are you sure to publish job to accept applications?"}
      />
      <ConfirmDialog
        open={unshareConfirm}
        setOpen={setUnshareConfirm}
        action={removeShare}
        header={"Unshare Job"}
        message={"Are you sure to unshare job?"}
      />

      <div className="grid-cols-4 gap-4 md:grid">
        <div className="col-span-3 mb-3 bg-white rounded-lg lg:pt-0 dark:bg-slate-700">
          <div className="z-50 flex p-3 pt-6 mx-4 mb-3 bg-white rounded-lg dark:bg-slate-700">
            <div className="grid flex-grow grid-cols-4 h-fit gap-x-3 gap-y-1">
              {/* <img
                className="w-[100px] row-span-3 h-[100px] rounded"
                src={job.image?.url}
                alt={job.title}
              /> */}
              <h3 className="col-span-4 text-xl font-semibold">{job.title}</h3>
              <p className="col-span-4 font-semibold">{job.agent?.name}</p>
              <div className="flex items-center col-span-4 gap-2">
                <p className="flex items-center gap-1 px-2 py-1 rounded min-w-fit bg-slate-100 dark:bg-slate-600">
                  <Banknote />
                  <span>Agent Fees - {job.share_history?.amount_text}</span>
                </p>
                {job.job_type && (
                  <p className="px-2 py-1 rounded bg-slate-100 min-w-fit dark:bg-slate-600">
                    {job.job_type?.name}
                  </p>
                )}
                {job.sub_category && (
                  <p className="px-2 py-1 rounded bg-slate-100 min-w-fit dark:bg-slate-600">
                    {job.sub_category?.name}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col min-w-[200px] gap-3">
              <p className="flex items-center gap-2">
                <Clock />
                <span>Posted - {format(job.created_at, "MMMdd ,yyyy")}</span>
              </p>
              <p className="flex items-center gap-2 text-destructive">
                <Clock />
                <span>
                  Expired Date -{" "}
                  {job.job_expired_date
                    ? `${format(job.job_expired_date, "MMMdd ,yyyy")}`
                    : "None"}
                </span>
              </p>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-2 p-4 mx-4 mt-6 border rounded-lg shadow lg:mx-8">
            <div className="py-2 text-sm h-fit">
              <p className="flex items-center gap-2">
                <BriefcaseBusiness />
                <span className="text-lg">Employee Type</span>
              </p>
              <span className="ml-8">{job.employment_type?.name}</span>
            </div>
            <div className="py-2 text-sm h-fit">
              <p className="flex items-center gap-2">
                <BadgeJapaneseYen />
                <span className="text-lg">Basic Salary</span>
              </p>
              <span className="ml-8">{job.salary_range}</span>
            </div>
            <div className="py-2 text-sm h-fit">
              <p className="flex items-center gap-2">
                <BadgeJapaneseYen />
                <span className="text-lg">Yearly Salary</span>
              </p>
              <span className="ml-8">{job.yearly_salary}¥</span>
            </div>
            <div className="py-2 text-sm h-fit">
              <p className="flex items-center gap-2">
                <Languages />
                <span className="text-lg">Japanese Language</span>
              </p>
              <span className="ml-8">
                {job?.japan_level?.name ? job.japan_level.name : "-"}
              </span>
            </div>
            <div className="py-2 text-sm h-fit">
              <p className="flex items-center gap-2">
                <UserCheck />
                <span className="text-lg">Foreign Apply</span>
              </p>
              <span className="ml-8">{job?.foreign_apply ? "Yes" : "No"}</span>
            </div>
            <div className="py-2 text-sm h-fit">
              <p className="flex items-center gap-2">
                <Users />
                <span className="text-lg">Required People</span>
              </p>
              <span className="ml-8">
                {job?.anyone ? `${job.anyone} People` : "-"}
              </span>
            </div>
          </div>

          <div className="p-3 mx-4 max-h-[600px] overflow-y-scroll hide-scroll lg:mx-8">
            <h3 className="text-lg">Description</h3>
            <div className="mb-6 paragraph">
              {job.description ? parse(job.description) : ""}
            </div>

            <h3 className="text-lg">Requirements</h3>
            <div className="mb-6 paragraph">
              {job.requirements ? parse(job.requirements) : "-"}
            </div>

            <h3 className="text-lg">Address</h3>
            <p className="mb-6">
              • {job.postal_code} {job.state?.name} {job.address}
            </p>

            <div className="mb-6">
              <h3 className="text-lg">Nationalities</h3>
              <ul className="pl-8 list-disc">
                {job.nationalities?.map((nation) => (
                  <li key={nation.id}>{nation.name}</li>
                ))}
              </ul>
            </div>

            <h3 className="text-lg">Age</h3>
            <p className="mb-6">
              •{" "}
              {job.min_age || job.max_age
                ? `${job.min_age} yrs to ${job.max_age} yrs`
                : "Not set"}
            </p>

            <h3 className="text-lg">Bonus</h3>
            <p>• {job.bonus ? "Yes" : "No"}</p>
            <div className="mb-6 paragraph">
              {job.bonus_explain ? parse(job.bonus_explain) : ""}
            </div>

            <h3 className="text-lg">House Support</h3>
            <p>• {job.housing_support ? "Yes" : "No"}</p>
            <div className="mb-6 paragraph">
              {job.housing_explain ? parse(job.housing_explain) : ""}
            </div>

            <h3 className="text-lg">Yearly Salary</h3>
            <p className="mb-6">• {job.yearly_salary} ¥</p>

            <h3 className="text-lg">Salary Tax</h3>
            <p>• {job.tax}</p>
            <div className="mb-4 paragraph">
              {job.tax_explain ? parse(job.tax_explain) : ""}
            </div>
            <div className="mb-6 paragraph">
              {job.tax_left_explain ? parse(job.tax_left_explain) : ""}
            </div>

            <h3 className="text-lg">Working Hour</h3>
            <p className="mb-6">
              • {job.working_hour} hours / {job.working_hour_per}
            </p>

            <h3 className="text-lg">Overtime</h3>
            <p className="mb-6">
              •{" "}
              {job.overtime
                ? `${job.overtime_per_month} hours / monthly`
                : "No"}
            </p>

            <h3 className="text-lg">Trail Period</h3>
            <p>• {job.trial_deadline ? "Yes" : "No"}</p>
            <div className="mb-6 paragraph">
              {job.trial_explain ? parse(job.trial_explain) : ""}
            </div>

            <h3 className="text-lg">Off Days</h3>
            <p>• {job.off_days_per_year} days / yearly</p>
            <div className="mb-6 paragraph">
              {job.off_days_explain ? parse(job.off_days_explain) : ""}
            </div>

            <h3 className="text-lg">Benefits</h3>
            <ul className="pl-8 mb-6 list-disc">
              {job.benefits && job.benefits.length
                ? job.benefits.map((b) => <li key={b.id}>{b.name}</li>)
                : "-"}
            </ul>

            <h3 className="text-lg">Advantages of the Job</h3>
            <div className="flex items-center mb-6 paragraph">
              {job.advantage ? parse(job.advantage) : ""}
            </div>

            <h3 className="text-lg">Job Category</h3>
            <p className="mb-6">• {job.sub_category?.name}</p>

            <h3 className="text-lg">Job Expired Date</h3>
            <p className="mb-6">• {job.job_expired_date}</p>

            <div className="mb-6">
              <h3 className="text-lg">Tags</h3>
              <ul className="pl-8 list-disc">
                {job.tags?.map((tag) => (
                  <li key={tag.id}>{tag.name}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="col-span-1">
          <div className="p-3 mb-3 bg-white rounded-lg dark:bg-slate-700">
            <div>
              <h3 className="text-lg font-semibold">Job Status</h3>
              <p className="flex items-center px-3 py-2 mt-1 border rounded-lg border-primary">
                <span className="flex-grow text-primary">Interviewing </span>
                <ChevronDown className="w-4 h-4 text-primary" />
              </p>
            </div>
            <div className="mt-8">
              <h3 className="text-lg font-semibold">Job Settings</h3>
              <div className="flex items-center gap-4 p-3 border-b">
                <Button
                  size="icon"
                  variant="secondary"
                  className="w-[50px] h-[50px] rounded-full cursor-default"
                >
                  <Share2 />
                </Button>
                <div className="flex-grow">
                  <h5 className="mb-1 text-blue-500">Share Job</h5>
                  <p className="text-primary">
                    {job.has_shared ? "Shared" : "Unshared"}
                  </p>
                </div>
                <Switch
                  onCheckedChange={() => {
                    if (!job.has_shared) {
                      setShareDialog(true);
                    } else {
                      setUnshareConfirm(true);
                    }
                  }}
                  checked={job.has_shared}
                />
              </div>
              <div className="flex items-center gap-4 p-3 mt-4 border-b">
                <Button
                  size="icon"
                  variant="secondary"
                  className="w-[50px] h-[50px] rounded-full cursor-default"
                >
                  <UserRoundCheck />
                </Button>
                <div className="flex-grow">
                  <h5 className="mb-1 text-blue-500">Publish Job</h5>
                  <p className="text-primary">
                    {job.publish ? "Published" : "Unpublished"}
                  </p>
                </div>
                <Switch
                  onCheckedChange={() => {
                    if (job.publish) {
                      setPublishDialog(true);
                    } else {
                      setConfirm(true);
                    }
                  }}
                  checked={job.publish}
                />
              </div>
              <Link
                to={`/jobs/edit/${job.id}`}
                className="flex items-center gap-4 p-3 mt-4 border-b"
              >
                <Button
                  size="icon"
                  variant="secondary"
                  className="w-[50px] h-[50px] rounded-full cursor-default"
                >
                  <Edit />
                </Button>
                <h5 className="flex-grow mb-1 text-blue-500">Edit Job</h5>
                <ArrowRight />
              </Link>
            </div>
          </div>
          <div className="grid grid-cols-4 p-3 mb-3 bg-white rounded-lg dark:bg-slate-700 gap-x-4 gap-y-1">
            <Button
              size="icon"
              variant="secondary"
              className="w-[50px] row-span-4 h-[50px] bg-blue-100 text-blue-600 rounded-full cursor-default"
            >
              <Users />
            </Button>
            <h3 className="col-span-3 text-blue-500">Applications Sent</h3>
            <p className="col-span-3 text-2xl font-bold ">12 </p>
          </div>
          <div className="grid grid-cols-4 p-3 mb-3 bg-white rounded-lg dark:bg-slate-700 gap-x-4 gap-y-1">
            <Button
              size="icon"
              variant="secondary"
              className="w-[50px] row-span-4 h-[50px] bg-red-100 text-red-700 rounded-full cursor-default"
            >
              <SquareActivity />
            </Button>
            <h3 className="col-span-3 text-blue-500">Post Views</h3>
            <p className="col-span-3 text-2xl font-bold ">100 </p>
          </div>
          <div className="grid grid-cols-4 p-3 mb-3 bg-white rounded-lg dark:bg-slate-700 gap-x-4 gap-y-1">
            <Button
              size="icon"
              variant="secondary"
              className="w-[50px] row-span-4 h-[50px] bg-teal-100 text-teal-700 rounded-full cursor-default"
            >
              <Bookmark />
            </Button>
            <h3 className="col-span-3 text-blue-500">Posts Saved</h3>
            <p className="col-span-3 text-2xl font-bold ">20 </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobInfo;
