import GridView from "@/components/jobs/GridView";
import ListView from "@/components/jobs/ListView";
import { Button } from "@/components/ui/button";
import Error from "@/components/ui/error";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import useQuery from "@/hooks/use-query";
import { Job } from "@/types";
import {
  AlignJustify,
  BriefcaseBusiness,
  ChevronLeft,
  ChevronRight,
  Edit,
  LayoutGrid,
  Share2,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

type Layout = "grid" | "list";

const JobsView = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [layout, setLayout] = useState<Layout>("list");
  const [list, setList] = useState({
    type: "all",
    jobs: [],
  });
  const {
    data: jobs,
    loading,
    error,
    meta,
  } = useQuery(
    "jobs",
    { page: searchParams.get("page"), per_page: searchParams.get("per_page") },
    location.search,
  );

  useEffect(() => {
    if (!loading) {
      setList((l) => {
        return { ...l, jobs: jobs };
      });
    }
  }, [loading]);

  if (error) {
    return <Error />;
  }

  const paginate = (page: string | number, perPage: string | number) => {
    setSearchParams({ page: page.toString(), per_page: perPage.toString() });
  };

  const changeListStyle = (type: string) => {
    if (type == "all") {
      setList({
        type: "all",
        jobs: jobs,
      });
    } else {
      setList({
        type: "shared",
        jobs: jobs.filter(
          (job: Job) => job.share_history && job.share_history.amount,
        ),
      });
    }
  };

  return !loading ? (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <h1 className="flex-grow text-xl font-semibold">Jobs List</h1>
          <div className="flex gap-4">
            <Button
              onClick={() => changeListStyle("all")}
              variant={list.type == "all" ? "default" : "outline"}
              className="flex items-center gap-2 rounded-full"
            >
              <BriefcaseBusiness />
              <span>All Jobs</span>
            </Button>
            <Button
              onClick={() => changeListStyle("shared")}
              variant={list.type == "all" ? "outline" : "default"}
              className="flex items-center gap-2 rounded-full"
            >
              <Share2 />
              <span>Shared Jobs</span>
            </Button>
          </div>
        </div>

        <div className="flex items-center gap-3">
          {layout == "grid" ? (
            <Button
              onClick={() => {
                setLayout("list");
              }}
              size="icon"
              className="hover:bg-secondary bg-secondary text-primary"
            >
              <LayoutGrid />
            </Button>
          ) : (
            <Button
              onClick={() => {
                setLayout("grid");
              }}
              size="icon"
              className="hover:bg-secondary bg-secondary text-primary"
            >
              <AlignJustify />
            </Button>
          )}
          <Button
            size="icon"
            className="hover:bg-secondary bg-secondary text-primary"
          >
            <Edit />
          </Button>
          <Select defaultValue="last 30 days">
            <SelectTrigger className="w-[180px] hover:bg-secondary bg-secondary text-slate-800">
              <SelectValue placeholder="Theme" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="last 30 days">last 30 days</SelectItem>
              <SelectItem value="dark">Dark</SelectItem>
              <SelectItem value="system">System</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      <div>
        {list.jobs && layout == "grid" ? (
          <GridView jobs={list.jobs} />
        ) : (
          <ListView jobs={list.jobs} />
        )}
      </div>
      <div className="flex justify-center gap-2 mt-6">
        <Button
          size="icon"
          variant="outline"
          disabled={meta.current_page < 2}
          onClick={() => {
            paginate(meta.current_page - 1, meta.per_page);
          }}
        >
          <ChevronLeft />
        </Button>
        <Button
          size="icon"
          variant="outline"
          disabled={meta.current_page >= meta.last_page}
          onClick={() => {
            paginate(meta.current_page + 1, meta.per_page);
          }}
        >
          <ChevronRight />
        </Button>
      </div>
    </div>
  ) : (
    <div className="p-2">
      <Skeleton className="h-[100px] mb-4" />
      <Skeleton className="h-[100px] mb-4" />
      <Skeleton className="h-[100px] mb-4" />
      <Skeleton className="h-[100px] mb-4" />
      <Skeleton className="h-[100px] mb-4" />
      <Skeleton className="h-[100px] mb-4" />
    </div>
  );
};

export default JobsView;
