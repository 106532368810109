import { Job } from "@/types";
import parse from "html-react-parser";

const JobPreview = ({ job }: { job: Job }) => {
    const user = JSON.parse(localStorage.getItem("user"));
  return (
    <div className="relative p-3 pb-12 bg-white rounded-lg dark:bg-slate-700 dark:text-slate-100">
      <div className="grid grid-cols-4 gap-x-3 gap-y-1">
        <h3 className="col-span-3 text-xl font-semibold h-fit">{job.title}</h3>
        <p className="col-span-3 font-semibold h-fit">{user?.name}</p>
        <p className="col-span-3 h-fit">Agent ID - {job.agent ? job.agent.id : user?.agent_id}</p>
      </div>
      <div className="my-6">
        <h3 className="mb-1 text-xl font-semibold">Salary Range</h3>
        <p>
          {job.min_salary} - {job.max_salary} / yearly
        </p>
      </div>
      <div className="my-6">
        <h3 className="mb-1 text-xl font-semibold">Job Description</h3>
        <div className="max-h-[25vh] paragraph overflow-scroll hide-scroll">
          {job.description ? parse(job.description) : "-"}
        </div>
      </div>
      <div className="my-6">
        <h3 className="mb-1 text-xl font-semibold">Requirements</h3>
        <div className="max-h-[25vh] paragraph overflow-scroll hide-scroll">
          {job.requirements ? parse(job.requirements) : "-"}
        </div>
      </div>
    </div>
  );
};

export default JobPreview;
