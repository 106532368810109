import {
  LayoutDashboard,
  Briefcase,
  Cpu,
  Settings,
  BriefcaseBusiness,
  BadgeCheck,
  FileText,
  Bookmark,
  ListCheck,
} from "lucide-react";

export default [
  {
    name: "Dashboard",
    icon: <LayoutDashboard />,
    link: "/",
  },
  {
    name: "My Jobs",
    icon: <Briefcase />,
    link: "/jobs?page=1&per_page=15",
  },
  {
    name: "Job Applications",
    icon: <ListCheck />,
    link: "/job-applications?page=1&per_page=15",
  },
  {
    name: "Shared Jobs",
    icon: <BriefcaseBusiness />,
    link: "/shared-jobs?page=1&per_page=15",
  },
  {
    name: "Saved Jobs",
    icon: <Bookmark />,
    link: "/saved-jobs?page=1&per_page=15",
  },
  {
    name: "Applied List",
    icon: <BadgeCheck />,
    link: "/applied-list?page=1&per_page=15",
  },
  {
    name: "CV List",
    icon: <FileText />,
    link: "/cv-list?page=1&per_page=15",
  },
  {
    name: "dashboard",
    icon: <Cpu />,
    link: "/contact",
  },
  {
    name: "Menu Settings",
    icon: <Settings />,
    link: "/contact",
  },
];
