import AppliedJobs from "@/components/cv-list/AppliedJobs";
import CvForms from "@/components/cv-list/CvForms";
import UserCv from "@/components/cv-list/UserCv";
import UserInfo from "@/components/cv-list/UserInfo";
import { Button } from "@/components/ui/button";
import Error from "@/components/ui/error";
import { Skeleton } from "@/components/ui/skeleton";
import useQuery from "@/hooks/use-query";
import { BriefcaseBusiness, ChevronLeft, FileText, Info } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const CvDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [tab, setTab] = useState<string>(
    searchParams.get("tab") == "forms" ? "forms" : "info",
  );
  const [reloadKey, setReloadKey] = useState(Math.random());
  const {
    data: user,
    loading,
    error,
  } = useQuery(`agent-users/${id}`, {}, reloadKey);
  const reload = () => {
    setReloadKey(Math.random());
  };

  useEffect(() => {
    setSearchParams({ tab: tab }, { replace: true });
  }, [tab, setSearchParams]);

  if (error) {
    return <Error />;
  }

  return !loading ? (
    <div>
      <div className="flex flex-wrap items-center flex-grow gap-4 mb-4">
        <Button
          onClick={() =>
            window.history.length > 2
              ? navigate(-1)
              : navigate("cv-list?page=1&per_page=15")
          }
          size="icon"
          className="rounded-full"
        >
          <ChevronLeft />
        </Button>
        <Button
          onClick={() => setTab("info")}
          variant={tab == "info" ? "default" : "outline"}
          className="flex items-center gap-2 rounded-full"
        >
          <Info className="w-4 h-4" />
          <span>Profile Info</span>
        </Button>
        <Button
          onClick={() => setTab("cv")}
          variant={tab == "cv" ? "default" : "outline"}
          className="flex items-center gap-2 rounded-full"
        >
          <FileText className="w-4 h-4" />
          <span>CV Files</span>
        </Button>
        <Button
          onClick={() => setTab("forms")}
          variant={tab == "forms" ? "default" : "outline"}
          className="flex items-center gap-2 rounded-full"
        >
          <BriefcaseBusiness className="w-4 h-4" />
          <span>Cv Forms</span>
        </Button>
        <Button
          onClick={() => setTab("jobs")}
          variant={tab == "jobs" ? "default" : "outline"}
          className="flex items-center gap-2 rounded-full"
        >
          <BriefcaseBusiness className="w-4 h-4" />
          <span>Applied Jobs</span>
        </Button>
      </div>
      {tab == "info" ? (
        <UserInfo user={user} />
      ) : tab == "cv" ? (
        <UserCv user={user} />
      ) : tab == "jobs" ? (
        <AppliedJobs user={user} />
      ) : (
        <CvForms id={id} reload={reload} user={user} />
      )}
    </div>
  ) : (
    <div className="grid items-center grid-cols-1 gap-4 p-2 lg:grid-cols-3">
      <Skeleton className="rounded-full mx-auto h-[270px] w-[270px]" />
      <div className="grid grid-cols-1 gap-2 h-fit lg:col-span-2 lg:grid-cols-2">
        <Skeleton className="h-[70px]" />
        <Skeleton className="h-[70px]" />
        <Skeleton className="h-[70px]" />
        <Skeleton className="h-[70px]" />
      </div>
    </div>
  );
};

export default CvDetails;
