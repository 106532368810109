import { useState } from "react";

import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Building, ChevronRight, User } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Job } from "@/types";

type Props = {
  setTab: React.Dispatch<React.SetStateAction<string>>;
  job: Job;
  setJob: React.Dispatch<React.SetStateAction<Job>>;
};
const InitialInformation = ({ setTab, job, setJob }: Props) => {
  const [initials, setInitials] = useState({
    type: job.employee_type,
    foreign_apply: job.foreign_apply ? "yes" : "no",
  });
  const handleSubmit = () => {
    const str = JSON.stringify(initials);
    sessionStorage.setItem("initials", str);
    setJob(() => {
      return {
        ...job,
        foreign_apply: initials.foreign_apply == "yes",
        type: initials.type,
      };
    });
    setTab("1");
  };

  return (
    <div>
      <div className="p-3 mb-3 bg-white rounded-lg dark:bg-slate-700">
        <h2 className="text-xl">
          Fill in the information to post a staff call.
        </h2>
      </div>
      <div className="p-3 bg-white rounded-lg dark:bg-slate-700">
        <h2 className="text-xl font-semibold">Select One</h2>
        <p className="my-2">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptates
          accusantium aliquam optio quo consequuntur deleniti totam{" "}
        </p>
        <RadioGroup
          value={initials.type}
          onValueChange={(e) => setInitials({ ...initials, type: e })}
          defaultValue={initials.type}
        >
          <div
            onClick={() => setInitials({ ...initials, type: "agent" })}
            className={`flex items-center justify-between my-3 p-4 border rounded-lg ${
              initials.type == "agent"
                ? "border-primary"
                : "dark:border-slate-300"
            }`}
          >
            <Label className="flex items-center gap-2" htmlFor="agent">
              <User />
              <span className="text-lg font-bold">Agent</span>
            </Label>
            <RadioGroupItem value="agent" id="agent" />
          </div>
          <div
            onClick={() => setInitials({ ...initials, type: "company" })}
            className={`flex items-center justify-between p-4 border rounded-lg ${
              initials.type == "company"
                ? "border-primary"
                : "dark:border-slate-300"
            }`}
          >
            <Label className="flex items-center gap-2" htmlFor="company">
              <Building />
              <span className="text-lg font-bold">Company</span>
            </Label>
            <RadioGroupItem value="company" id="company" />
          </div>
        </RadioGroup>
        <h2 className="mt-8 text-xl font-semibold">Foreign Apply</h2>
        <p className="my-2">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptates
          accusantium aliquam optio quo consequuntur deleniti totam{" "}
        </p>
        <RadioGroup
          value={initials.foreign_apply}
          onValueChange={(e) => setInitials({ ...initials, foreign_apply: e })}
          defaultValue={initials.foreign_apply}
          className="flex gap-4 mt-2"
        >
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="yes" id="yes" />
            <Label className="text-lg" htmlFor="yes">
              Yes
            </Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="no" id="no" />
            <Label className="text-lg" htmlFor="no">
              No
            </Label>
          </div>
        </RadioGroup>
        <div className="flex justify-end my-8">
          <Button
            onClick={handleSubmit}
            className="flex items-center gap-2 rounded-full"
          >
            <span>Next</span>
            <ChevronRight />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InitialInformation;
