import { ArrowUpFromLine, Ellipsis } from "lucide-react";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";
import { Cv, CvForm } from "@/types";

type Props = {
  user: { [key: string]: unknown };
  cv: Cv | null;
  information: string;
  setInformation: React.Dispatch<React.SetStateAction<string>>;
  apply: () => void;
  loading: boolean;
  goBack: (t: "list" | "cv" | "apply" | "success") => void;
  cvForm: CvForm | null;
};
const ConfirmApplication = ({
  user,
  cv,
  cvForm,
  apply,
  information,
  setInformation,
  loading,
  goBack,
}: Props) => {
  return (
    <div className="grid-cols-1 gap-4 md:grid md:grid-cols-2">
      <div className="md:col-span-2">
        <h1 className="text-lg">
          Confirm Application for{" "}
          {typeof user.name == "string" ? user.name : ""}
        </h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum nihil
          corporis mollitia fuga earum repellendus minus et optio nemo cum
          reprehenderit.
        </p>
      </div>
      <div className="p-3 mt-4 border-2 border-green-500 rounded-lg shadow md:mt-0">
        {!!cv?.id && (
          <iframe
            src={typeof cv.url == "string" ? cv.url : ""}
            width="100%"
            height="400px"
          ></iframe>
        )}
        {!!cvForm?.id && (
          <div className="p-1 border border-green-500 rounded-lg lg:p-3">
            <h3 className="mb-2 text-lg font-semibold">{cvForm.label}</h3>
            <p className="grid grid-cols-2 gap-2">
              <span>occupation</span> - {cvForm.occupation}
            </p>
            <p className="grid grid-cols-2 gap-2">
              <span>Name</span> - {cvForm.name}
            </p>
            <p className="grid grid-cols-2 gap-2">
              <span>name_japan</span> - {cvForm.name_japan}
            </p>
            <p className="grid grid-cols-2 gap-2">
              <span>age</span> - {cvForm.age}
            </p>
            <p className="grid grid-cols-2 gap-2">
              <span>Date of Birth</span> - {cvForm.dob}
            </p>
            <p className="grid grid-cols-2 gap-2">
              <span>gender</span> - {cvForm.gender}
            </p>
            <p className="grid grid-cols-2 gap-2">
              <span>email</span> - {cvForm.email}
            </p>
            <p className="grid grid-cols-2 gap-2">
              <span>Phone No.</span> - {cvForm.phone_no}
            </p>
          </div>
        )}
      </div>
      <div className="p-3 mt-4 border rounded-lg border-slate-400 md:mt-0">
        <Label className="text-lg">Information</Label>
        <Textarea
          value={information}
          onChange={(e) => setInformation(e.target.value)}
          placeholder="Explain why you are suitable for this job."
          rows={9}
          className="mt-2"
        />
      </div>
      <div className="flex justify-center col-span-2 gap-4 py-4 border-t">
        <Button
          variant="outline"
          className="bg-transparent rounded-full text-primary"
          onClick={() => goBack("cv")}
        >
          Back
        </Button>
        <Button
          onClick={apply}
          className="flex items-center gap-2 rounded-full"
          disabled={loading}
        >
          {loading ? <Ellipsis /> : <ArrowUpFromLine />}
          {loading ? <span>Applying</span> : <span>Apply Now</span>}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmApplication;
