import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import StepOne from "@/components/jobs/create/StepOne";
import StepTwo from "@/components/jobs/create/StepTwo";
import StepThree from "@/components/jobs/create/StepThree";
import StepFour from "@/components/jobs/create/StepFour";
import StepFive from "@/components/jobs/create/StepFive";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Job } from "@/types";
import InitialInformation from "@/components/jobs/create/InitialInformation";
import { Button } from "@/components/ui/button";
import { ArrowLeft, Ellipsis, Upload } from "lucide-react";
import ApiService from "@/services/ApiService";
import { useToast } from "@/hooks/use-toast";
import JobPreview from "./JobPreview";
const Create = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [tab, setTab] = useState("0");
  const [job, setJob] = useState<Job>({
    id: "",
    nationality_ids: [],
    job_type_id: 1,
    tag_ids: [],
    advantage: "",
    title: "",
    company_id: "",
    agent_id: "",
    postal_code: "",
    state_id: 1,
    japan_level_id: 1,
    address: "",
    employment_type_id: 1,
    anyone: 0,
    male: 0,
    female: 0,
    min_age: 0,
    max_age: 0,
    employee_type: "",
    sub_category_id: 0,
    category_id: 1,
    description: "",
    requirements: "",
    require_education: false,
    jp_language_require: false,
    pay_scale: "hourly",
    salary_cut_off: 0,
    salary_after_cut_off: "",
    explain_salary_cut_off: "",
    bonus: false,
    yearly_salary: 0,
    overtime: false,
    overtime_per_monthly: "",
    trial_deadline: false,
    trial_explain: "",
    housing_support: false,
    housing_explain: "",
    off_days_per_year: 1,
    off_days_explain: "",
    show_pay_by: "between",
    min_salary: 0,
    max_salary: 0,
    pr_point: 0,
    publish: true,
    job_expired_date: "",
    created_at: "",
    updated_at: "",
    deleted_at: "",
    fixed_salary: 0,
    tax: 1,
    tax_explain: "",
    tax_left_explain: "",
    bonus_explain: "",
    working_hour: 0,
    working_hour_per: "monthly",
    overtime_per_month: 0,
    access_explain: "",
    foreign_apply: false,
    applied_users: [],
    benefit_ids: [],
    errors: { hello: "world" }
  });
  const [createLoading, setCreateLoading] = useState(false);

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement>,
    nextTab: string
  ) => {
    e.preventDefault();
    setTab(nextTab);
  };

  const createJob = () => {
    setCreateLoading(true);
    ApiService.post(`jobs`, job)
      .then((res) => {
        window.sessionStorage.removeItem("initials");
        toast({ title: res.data.message, variant: "success" });
        setCreateLoading(false);
        navigate(`/jobs`);
      })
      .catch((err) => {
        setCreateLoading(false);
        setJob({ ...job, errors: err.response?.data?.errors });
        toast({
          title: "Error creating job.",
          description: err.response?.data?.message,
          variant: "destructive"
        });
      });
  };

  return (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-4">
      <Tabs
        value={tab}
        defaultValue="1"
        className="relative lg:col-span-3 bg-white rounded-lg overflow-y-scroll hide-scroll h-[90vh] p-2 dark:bg-slate-600"
      >
        <Button
          asChild
          className="absolute top-0 left-0"
          size="icon"
          variant="ghost"
        >
          <Link to={`/jobs/${id}`}>
            <ArrowLeft />
          </Link>
        </Button>
        <TabsList className="w-full bg-transparent">
          <TabsTrigger
            onClick={() => setTab("0")}
            className="mx-1 rounded-full"
            value="0"
          >
            Initial Info
          </TabsTrigger>
          <TabsTrigger
            onClick={() => setTab("1")}
            className="mx-1 rounded-full"
            value="1"
          >
            Step 1
          </TabsTrigger>
          <TabsTrigger
            onClick={() => setTab("2")}
            className="mx-1 rounded-full"
            value="2"
          >
            Step 2
          </TabsTrigger>
          <TabsTrigger
            onClick={() => setTab("3")}
            className="mx-1 rounded-full"
            value="3"
          >
            Step 3
          </TabsTrigger>
          <TabsTrigger
            onClick={() => setTab("4")}
            className="mx-1 rounded-full"
            value="4"
          >
            Step 4
          </TabsTrigger>
          <TabsTrigger
            onClick={() => setTab("5")}
            className="mx-1 rounded-full"
            value="5"
          >
            Step 5
          </TabsTrigger>
        </TabsList>
        <TabsContent value="0">
          <InitialInformation setJob={setJob} job={job} setTab={setTab} />
        </TabsContent>
        <TabsContent value="1">
          <StepOne
            setTab={setTab}
            job={job}
            setJob={setJob}
            handleSubmit={handleSubmit}
          />
        </TabsContent>
        <TabsContent value="2">
          <StepTwo
            setTab={setTab}
            job={job}
            setJob={setJob}
            handleSubmit={handleSubmit}
          />
        </TabsContent>
        <TabsContent value="3">
          <StepThree
            setTab={setTab}
            job={job}
            setJob={setJob}
            handleSubmit={handleSubmit}
          />
        </TabsContent>
        <TabsContent value="4">
          <StepFour
            setTab={setTab}
            job={job}
            setJob={setJob}
            handleSubmit={handleSubmit}
          />
        </TabsContent>
        <TabsContent value="5">
          <StepFive
            setTab={setTab}
            job={job}
            setJob={setJob}
            handleSubmit={handleSubmit}
          />
        </TabsContent>
        <div className="flex justify-center my-8">
          <Button
            disabled={createLoading}
            onClick={createJob}
            className="flex items-center w-full gap-2"
            size="lg"
          >
            {!createLoading ? <Upload /> : <Ellipsis />}
            {!createLoading ? <span>Create Job</span> : <span>Loading</span>}
          </Button>
        </div>
      </Tabs>
      <JobPreview job={job} />
    </div>
  );
};

export default Create;
