import ApplierAppointments from "@/components/job-applications/ApplierAppointments";
import Appointments from "@/components/job-applications/Appointments";
import Documents from "@/components/job-applications/Documents";
import ProcessInfo from "@/components/job-applications/ProcessInfo";
import UserDetails from "@/components/job-applications/UserDetails";
import { Button } from "@/components/ui/button";
import Error from "@/components/ui/error";
import { Skeleton } from "@/components/ui/skeleton";
import useQuery from "@/hooks/use-query";
import {
  ChevronLeft,
  FileText,
  Folder,
  Info,
  MessageSquare,
  Users,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const ApplicationDetails = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const isApplier = searchParams.get("type") == "agent";

  const id = params.id ? params.id : "";
  const [tab, setTab] = useState<string>(searchParams.get("tab") ?? "cv");

  useEffect(() => {
    setSearchParams(
      { tab: tab, type: isApplier ? "agent" : "" },
      { replace: true },
    );
  }, [tab, setSearchParams, isApplier]);

  const {
    data: application,
    loading,
    error,
  } = useQuery(`job-applications/${id}`);
  if (error) {
    return <Error />;
  }

  return !loading ? (
    <div>
      <div className="items-center justify-between mb-4 lg:flex">
        <div className="flex flex-wrap items-center flex-grow gap-4 mb-4">
          <Button
            onClick={() => navigate(-1)}
            size="icon"
            className="rounded-full"
          >
            <ChevronLeft />
          </Button>
          <Button
            onClick={() => setTab("cv")}
            variant={tab == "cv" ? "secondary" : "outline"}
            className="flex items-center gap-2 rounded-full"
          >
            <FileText className="w-4 h-4" />
            <span>CV Info</span>
          </Button>
          <Button
            onClick={() => setTab("process")}
            variant={tab == "process" ? "secondary" : "outline"}
            className="flex items-center gap-2 rounded-full"
          >
            <Info className="w-4 h-4" />
            <span>Process Info</span>
          </Button>
          <Button
            onClick={() => setTab("appointment")}
            variant={tab == "appointment" ? "secondary" : "outline"}
            className="flex items-center gap-2 rounded-full"
          >
            <Users className="w-4 h-4" />
            <span>Appointment</span>
          </Button>
          <Button
            onClick={() => setTab("files")}
            variant={tab == "files" ? "secondary" : "outline"}
            className="flex items-center gap-2 rounded-full"
          >
            <Folder className="w-4 h-4" />
            <span>File Attachments</span>
          </Button>
        </div>
        <div>
          <Button
            variant="default"
            className="flex items-center gap-2 rounded-full"
          >
            <MessageSquare className="w-4 h-4" />
            <span>Chat</span>
          </Button>
        </div>
      </div>
      {tab == "cv" ? (
        <UserDetails isApplier={isApplier} application={application} />
      ) : tab == "process" ? (
        <ProcessInfo isApplier={isApplier} application={application} />
      ) : tab == "appointment" ? (
        !isApplier ? (
          <Appointments application={application} />
        ) : (
          <ApplierAppointments application={application} />
        )
      ) : (
        <Documents isApplier={isApplier} application={application} />
      )}
    </div>
  ) : (
    <div className="flex gap-4 p-2">
      <div className="w-[60px] h-full flex flex-col justify-center gap-4">
        <Skeleton className="w-[52px] h-[52px] rounded-full" />
        <Skeleton className="w-[52px] h-[52px] rounded-full" />
        <Skeleton className="w-[52px] h-[52px] rounded-full" />
        <Skeleton className="w-[52px] h-[52px] rounded-full" />
        <Skeleton className="w-[52px] h-[52px] rounded-full" />
      </div>
      <div className="flex flex-col items-center justify-center flex-grow gap-4">
        <Skeleton className="h-[180px] w-[180px]" />
        <Skeleton className="h-[50px] w-[240px]" />
        <Skeleton className="h-[500px] w-[80%]" />
      </div>
    </div>
  );
};

export default ApplicationDetails;
