import { User } from "@/types";
import { Button } from "../ui/button";
import { Edit, Link as IconLink } from "lucide-react";
import { Link } from "react-router-dom";

const CvForms = ({
  user,
  id,
}: {
  user: User;
  reload: () => void;
  id: number | string;
}) => {
  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-xl font-semibold">Created Cv Forms</h1>
        <Button asChild>
          <Link to={`/cv-list/${user.id}/cv-forms/create`}>Create Cv Form</Link>
        </Button>
      </div>
      {!user.cv_forms?.length ? (
        <p className="py-12 text-center text-destructive">
          There is no cv form created yet.
        </p>
      ) : null}
      <ul className="relative grid grid-cols-1 md:grid-cols-2 mt-4 gap-4 min-h-[80vh]">
        {user.cv_forms?.map((cv) => (
          <li
            key={cv.id}
            className="p-3 overflow-x-scroll bg-white rounded-lg hide-scroll h-fit dark:bg-slate-600"
          >
            <div className="flex items-center justify-between">
              <h3 className="mb-2 text-lg font-semibold">{cv.label}</h3>
              <Button variant="secondary">
                <Link to={`/cv-list/${id}/cv-forms/${cv.id}`}>
                  <Edit />
                </Link>
              </Button>
            </div>
            <p className="grid grid-cols-2 gap-2 mt-1">
              <span>• Occupation </span>
              <span> - {cv.occupation}</span>
            </p>
            <p className="grid grid-cols-2 gap-2 mt-1">
              <span>• Name </span>
              <span> - {cv.name}</span>
            </p>
            <p className="grid grid-cols-2 gap-2 mt-1">
              <span>• Name in Japan </span>
              <span> - {cv.name_japan}</span>
            </p>
            <p className="grid grid-cols-2 gap-2 mt-1">
              <span>• Age </span>
              <span> - {cv.age}</span>
            </p>
            <p className="grid grid-cols-2 gap-2 mt-1">
              <span>• Date of Birth </span>
              <span> - {cv.dob}</span>
            </p>
            <p className="grid grid-cols-2 gap-2 mt-1">
              <span>• Gender </span>
              <span> - {cv.gender}</span>
            </p>
            <p className="grid grid-cols-2 gap-2 mt-1">
              <span>• Email </span>
              <span> - {cv.email}</span>
            </p>
            <p className="grid grid-cols-2 gap-2 mt-1">
              <span>• Phone Number </span>
              <span> - {cv.phone_no}</span>
            </p>
            <p className="grid grid-cols-2 gap-2 mt-1">
              <span>• Postal Code </span>
              <span> - {cv.postal_code}</span>
            </p>
            <p className="grid grid-cols-2 gap-2 mt-1">
              <span>• Address </span>
              <span> - {cv.address}</span>
            </p>
            <p className="grid grid-cols-2 gap-2 mt-1">
              <span>• Facebook Link </span>
              <a
                target="_blank"
                className="flex text-primary"
                href={cv.facebook_profile_link}
              >
                {" "}
                - <IconLink className="ml-2" />
              </a>
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CvForms;
