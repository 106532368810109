import { Job } from "@/types";
import {
  BadgeEuro,
  BadgeJapaneseYen,
  Banknote,
  Bookmark,
  BriefcaseBusiness,
  Clock,
  Languages,
  LayoutTemplate,
  MapPin,
  User,
  UserCheck,
} from "lucide-react";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";
import ApiService from "@/services/ApiService";
import { useToast } from "@/hooks/use-toast";
import { format } from "date-fns";

const JobCard = ({ job, className }: { job: Job; className?: string }) => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const saveJob = () => {
    ApiService.post(`jobs/${job.id}/save`, {})
      .then((res) => {
        console.log(res);
        toast({
          title: res.data?.message,
          description: res.data?.message,
          variant: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Error saving job.",
          description: err.response.data?.message,
          variant: "destructive",
        });
      });
  };
  return (
    <li
      key={job.id}
      className={`p-3 list-none bg-white rounded-lg dark:bg-slate-600 ${className}`}
    >
      <div className="flex gap-2">
        {/* <img
             onClick={() => navigate(`/shared-jobs/${job.id}`)}
              src={job.image?.url}
              className="w-[75px] min-w-[75px] cursor-pointer h-[75px] rounded-lg object-cover"
              alt="Job image"
            /> */}
        <div
          onClick={() => navigate(`/shared-jobs/${job.id}`)}
          className="flex-grow cursor-pointer"
        >
          <h3 className="text-lg line-clamp-2">{job.title}</h3>
          <h4 className="my-1">{job.agent?.name}</h4>
          <h6 className="flex items-center gap-1 text-sm">
            <MapPin className="w-4 h-4" />
            <span className="line-clamp-2">{job.address}</span>
          </h6>
        </div>
        <Button
          onClick={saveJob}
          className={`${job.is_saved ? "text-primary" : ""}`}
          size="icon"
          variant="ghost"
        >
          <Bookmark />
        </Button>
      </div>
      <div
        onClick={() => navigate(`/shared-jobs/${job.id}`)}
        className="flex flex-wrap gap-2 mt-4 cursor-pointer"
      >
        <div className="flex items-center justify-center gap-1 px-4 py-2 text-sm rounded-full h-fit bg-slate-200 dark:bg-slate-500">
          <BriefcaseBusiness />
          <span>{job.employment_type?.name}</span>
        </div>
        <div className="flex items-center justify-center gap-1 px-4 py-2 text-sm rounded-full h-fit bg-slate-200 dark:bg-slate-500">
          <Languages />
          <span>{job?.japan_level?.name ? job.japan_level.name : "-"}</span>
        </div>
        <div className="flex items-center justify-center gap-1 px-4 py-2 text-sm rounded-full h-fit bg-slate-200 dark:bg-slate-500">
          <User />
          <span>{job.job_type?.name}</span>
        </div>
        <div className="flex items-center justify-center gap-1 px-4 py-2 text-sm rounded-full h-fit bg-slate-200 dark:bg-slate-500">
          <UserCheck />
          <span>Foreign Apply - {job.foreign_apply ? "Yes" : "No"}</span>
        </div>
        <div className="flex items-center justify-center gap-1 px-4 py-2 text-sm rounded-full h-fit bg-slate-200 dark:bg-slate-500">
          <BadgeEuro />
          <span>Bonus - {job.bonus ? "Yes" : "No"}</span>
        </div>
        <div className="flex items-center justify-center gap-1 px-4 py-2 text-sm rounded-full h-fit bg-slate-200 dark:bg-slate-500">
          <LayoutTemplate />
          <span>Job Category - {job.sub_category?.name}</span>
        </div>
      </div>
      <div
        onClick={() => navigate(`/shared-jobs/${job.id}`)}
        className="flex items-center gap-1 px-4 py-2 my-4 text-sm rounded-full cursor-pointer w-fit h-fit bg-slate-200 dark:bg-slate-500"
      >
        <BadgeJapaneseYen />
        <span>{job.salary_range}</span>
      </div>
      <hr />
      <div
        onClick={() => navigate(`/shared-jobs/${job.id}`)}
        className="flex justify-between gap-4 mt-4 text-sm cursor-pointer"
      >
        <p className="flex items-center gap-1">
          <Banknote className="opacity-80" />
          <span>Agent Fee - {job.share_history?.amount_text}</span>
        </p>
        <p className="flex items-center gap-1">
          <Clock className="opacity-80" />
          <span>{format(job.created_at, "dd-MMM-yyyy")}</span>
        </p>
      </div>
    </li>
  );
};

export default JobCard;
