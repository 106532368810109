import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/hooks/use-toast";
import ApiService from "@/services/ApiService";
import { useState } from "react";
import { Link } from "react-router-dom";

const Login = () => {
  //auth/login
  const { toast } = useToast();
  const [user, setUser] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: "", password: "" });
  const login = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    ApiService.post("auth/login", user)
      .then((res) => {
        console.log(res);
        localStorage.setItem("token", res.data.data?.token);
        if (res.data.data?.user) {
          localStorage.setItem("user", JSON.stringify(res.data.data?.user));
        }
        window.location.assign("/");
      })
      .catch((err) => {
        console.log(err);
        setErrors(err.response.data?.errors);
        toast({
          title: "Error logging in.",
          description: err.response.data?.message,
          variant: "destructive",
        });
      });
  };
  return (
    <div className="bg-white dark:text-slate-700">
      <div className="items-center justify-center w-screen h-screen max-w-screen-xl p-4 mx-auto md:flex">
        <img className="mx-auto md:mx-0" src="/login.svg" alt="" />
        <form onSubmit={login} className="md:w-1/2">
          <h1 className="mb-8 text-2xl font-bold text-center">
            Login to OOAK JOB
          </h1>
          <div className="mb-4">
            <Label htmlFor="email">Email</Label>
            <Input
              name="email"
              type="email"
              value={user.email}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
              className="w-full mt-1"
            />
            {errors.email && (
              <p className="text-sm text-destructive">{errors.email}</p>
            )}
          </div>
          <div className="mb-4">
            <Label htmlFor="password">Password</Label>
            <Input
              name="password"
              type="password"
              value={user.password}
              onChange={(e) => setUser({ ...user, password: e.target.value })}
              className="w-full mt-1"
            />
            {errors.password && (
              <p className="text-sm text-destructive">{errors.password}</p>
            )}
          </div>
          <Button type="submit" className="w-full mb-2 text-lg rounded-lg">
            LOGIN
          </Button>
          <Link to="/password-reset">Forgot Password?</Link>
          <div className="flex items-center gap-2 mt-4">
            <hr className="flex-grow" />
            <p>OR</p>
            <hr className="flex-grow" />
          </div>
          <p className="flex justify-center mt-2">
            <span>New Account?</span>
            <Link to="/" className="ml-2 underline text-primary">
              Sign Up
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Login;
