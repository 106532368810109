import { Application } from "@/types";
import {
  Clock,
  IdCard,
  LayoutTemplate,
  MapPin,
  Phone,
  User,
  Link as LinkIcon,
} from "lucide-react";
import { Button } from "../ui/button";
import { format } from "date-fns";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { Link } from "react-router-dom";

const ApplierInformation = ({ application }: { application: Application }) => {
  const [agent, setAgent] = useState(false);
  return (
    <div className="grid grid-cols-1 gap-6 p-2 mb-8 rounded-lg md:p-6 sm:mt-0 bg-slate-800 dark:bg-slate-600 md:grid-cols-3">
      <Dialog open={agent} onOpenChange={setAgent}>
        <DialogContent className="md:min-w-[800px] bg-white dark:bg-slate-700">
          <DialogHeader>
            <DialogTitle>Agent Details</DialogTitle>
            <DialogDescription></DialogDescription>
            <div className="space-y-2">
              <h5 className="w-full text-lg font-semibold">
                {application.agent?.name}
              </h5>
              <p className="flex items-center w-full gap-2">
                <Phone />
                <span>{application.agent?.phone}</span>
              </p>
              <p className="flex items-center w-full gap-2">
                <MapPin />
                <span>
                  {application.agent?.postal_code}{" "}
                  {application.agent?.state?.name} {application.agent?.address}
                </span>
              </p>
              <p className="flex items-center w-full gap-2">
                <LinkIcon />
                <a
                  href={application.agent?.website}
                  target="_blank"
                  className="underline"
                >
                  {application.agent?.website}
                </a>
              </p>
              <div className="flex justify-center mt-2">
                <Button
                  variant="outline"
                  className="px-12 bg-transparent rounded-full"
                  onClick={() => setAgent(false)}
                >
                  Close
                </Button>
              </div>
            </div>
          </DialogHeader>
        </DialogContent>
      </Dialog>
      <div className="flex flex-col items-center gap-2 p-4 bg-white dark:bg-slate-300 rounded-xl text-slate-700">
        <h4 className="flex items-center gap-2 text-xl font-semibold">
          <IdCard className="w-12 h-12 px-2 text-white rounded-full bg-primary" />
          <span>Agent Information</span>
        </h4>
        <h5 className="w-full text-lg font-semibold">
          {application.agent?.name}
        </h5>
        <p className="flex items-center w-full gap-2">
          <Phone />
          <span>{application.agent?.phone}</span>
        </p>
        <p className="flex items-center w-full gap-2">
          <MapPin />
          <span>
            {application.agent?.postal_code} {application.agent?.state?.name}{" "}
            {application.agent?.address}
          </span>
        </p>
        <Button
          onClick={() => setAgent(true)}
          className="px-8 bg-transparent rounded-full mt-7 dark:border-slate-800"
          variant="outline"
        >
          Details
        </Button>
      </div>
      <div className="flex flex-col items-center gap-4 p-4 bg-white dark:bg-slate-300 rounded-xl text-slate-700">
        <h4 className="flex items-center gap-2 text-xl font-semibold">
          <User className="w-12 h-12 px-2 text-white rounded-full bg-primary" />
          <span>User Information</span>
        </h4>
        <p className="w-full">
          Job Status -{" "}
          <span className="text-primary">{application.status}</span>
        </p>
        <p className="w-full">
          Applied Date -{" "}
          <span className="text-primary">
            {format(application.created_at, "MMMdd , yyyy")}
          </span>
        </p>
        <Button
          className="px-8 mt-10 bg-transparent rounded-full dark:border-slate-800"
          variant="outline"
          asChild
        >
          <Link to={`/cv-list/${application.user_id}?tab=info`}>Details</Link>
        </Button>
      </div>
      <div className="p-4 bg-white dark:bg-slate-300 rounded-xl text-slate-700">
        <div className="flex justify-between gap-2 mb-1">
          <h4 className="text-lg font-bold">{application.job?.title}</h4>
          <p className="flex items-center gap-1 text-xs">
            <Clock className="w-4 h-4" />
            <span>{format(application.job?.created_at, "MMMdd , yyyy")}</span>
          </p>
        </div>
        <p className="mb-1 text-sm font-semibold">{application.agent?.name}</p>
        <p className="flex items-center gap-1 mb-1 text-sm">
          <MapPin className="w-4 h-4" /> {application.job?.address}
        </p>
        <p className="flex items-center gap-1 p-1.5 mb-1 text-sm rounded-lg bg-slate-200">
          <User className="w-4 h-4" /> {application.job?.job_type?.name} hello
          worlds
        </p>
        <p className="flex items-center gap-1 p-1.5 mb-1 text-sm rounded-lg bg-slate-200">
          <LayoutTemplate className="w-4 h-4" />{" "}
          {application.job?.sub_category?.name} hello worlds
        </p>
        <div className="flex justify-center mt-9">
          <Button
            className="px-8 bg-transparent rounded-full dark:border-slate-800"
            variant="outline"
            asChild
          >
            <Link to={`/shared-jobs/${application.job_id}`}>Details</Link>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ApplierInformation;
