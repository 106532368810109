import { Application } from "@/types";
import { format } from "date-fns";
import { Ellipsis, EllipsisVertical, Upload } from "lucide-react";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import ApiService from "@/services/ApiService";
import { Switch } from "../ui/switch";
import { Label } from "../ui/label";

type Props = {
  application: Application;
  isApplier?: boolean;
};
const Documents = ({ application, isApplier = false }: Props) => {
  const { toast } = useToast();
  const [dialog, setDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [error, setError] = useState<{ [key: string]: string }>({});
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const checkFiletype = (url: string) => {
    const splittedUrl = url.split(".");
    const mime = splittedUrl[splittedUrl.length - 1];
    if (
      mime.toLowerCase() == "png" ||
      mime == "jpg" ||
      mime == "jpeg" ||
      mime == "gif" ||
      mime == "svg"
    ) {
      return "image";
    } else if (mime.toLowerCase() == "pdf") {
      return "pdf";
    } else {
      return "other";
    }
  };

  const uploadDoc = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      toast({
        title: "Error uploading document.",
        description: "Please try again.",
        variant: "destructive",
      });
      return;
    }
    setFile(selectedFile);
    if (selectedFile) {
      const mimeType = selectedFile.type;
      const fileName = selectedFile.name.toLowerCase();

      if (mimeType.startsWith("image/")) {
        // If the file is an image, create a preview URL
        const imageUrl = URL.createObjectURL(selectedFile);
        setPreview(
          <img
            src={imageUrl}
            alt="Selected"
            className="max-w-[200px] w-full h-fit mx-auto"
          />,
        );
      } else if (mimeType === "application/pdf") {
        // If the file is a PDF, use iframe to display it
        const pdfUrl = URL.createObjectURL(selectedFile);
        setPreview(
          <iframe
            src={pdfUrl}
            title="PDF Preview"
            className="w-[180px] h-[240px] mx-auto"
          />,
        );
      } else {
        // If other file type, just show the name
        setPreview(<p>Selected file - {fileName}</p>);
      }
    } else {
      setPreview(null);
    }
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.set("file", file);
    formData.set("is_public", isApplier || isPublic ? "1" : "0");
    ApiService.post(
      `job-applications/${application.id}/upload-document`,
      formData,
      {
        "Content-Type": "multipart/form-data",
      },
    )
      .then((res) => {
        toast({
          title: res.data.message,
          description: res.data.message,
          variant: "success",
        });
        window.location.reload();
      })
      .catch((err) => {
        toast({
          title: err.response.data?.message,
          description: err.response.data?.errors?.file,
          variant: "destructive",
        });
        setError(err.response?.data?.errors);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="bg-">
      <Dialog open={dialog} onOpenChange={setDialog}>
        <DialogContent className="lg:min-w-[600px]">
          <DialogHeader>
            <DialogTitle>Upload Document</DialogTitle>
            <DialogDescription className="mb-4 opacity-0">.</DialogDescription>
            <form onSubmit={handleSubmit} className="mt-4">
              <div className="mb-4">{preview}</div>
              <div className="flex items-center justify-center w-full">
                <label
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center justify-center w-full h-40 rounded-lg cursor-pointer bg-slate-50 dark:bg-slate-600 hover:bg-slate-100 dark:hover:bg-slate-600"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      className="w-8 h-8 mb-4 text-slate-500 dark:text-slate-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2s text-slate-500 dark:text-slate-300">
                      Document
                    </p>
                    <p className="mb-2 text-sm text-slate-500 dark:text-slate-300">
                      <span className="font-semibold">Click to upload</span> or
                      drag and drop {isPublic ? "" : ""}
                    </p>
                    <p className="text-xs text-slate-500 dark:text-slate-300">
                      Accept all file types
                    </p>
                  </div>
                  <input
                    id="dropzone-file"
                    onChange={uploadDoc}
                    disabled={loading}
                    type="file"
                    className="hidden"
                  />
                </label>
              </div>
              {error?.file ? (
                <p className="text-destructive">{error.file}</p>
              ) : null}
              {!isApplier ? (
                <div className="flex items-center gap-4 px-4 py-3 mt-4 bg-white rounded dark:bg-slate-600 dark:text-slate-100">
                  <Switch
                    onCheckedChange={() => {
                      if (!isPublic) {
                        setIsPublic(true);
                      } else {
                        setIsPublic(false);
                      }
                    }}
                    checked={isPublic}
                    disabled={loading}
                  />
                  <Label htmlFor="is_public">Set doocument to public?</Label>
                </div>
              ) : null}
              {error?.is_public ? (
                <p className="text-destructive">{error.is_public}</p>
              ) : null}
              <div className="mt-4">
                <Button
                  className="flex items-center justify-center w-full gap-2"
                  size="lg"
                  disabled={loading}
                >
                  {!loading ? <Upload /> : <Ellipsis />}
                  <span>{!loading ? "Upload" : "Loading"}</span>
                </Button>
              </div>
            </form>
          </DialogHeader>
        </DialogContent>
      </Dialog>

      <div className="grid items-center grid-cols-6 gap-4 px-4 py-2 mb-4 text-white rounded bg-slate-800 dark:text-slate-100 dark:bg-slate-700">
        <p className="col-span-2 text-center">File</p>
        <p>Owner</p>
        <p>Is Public?</p>
        <p>Date</p>
        <Button
          onClick={() => {
            setDialog(true);
            setIsPublic(true);
          }}
          className="flex items-center gap-2 w-fit"
        >
          <Upload />
          <span className="hidden lg:inline">Upload Document</span>
        </Button>
      </div>
      <ul className="max-h-[900px] relative overflow-y-scroll hide-scroll">
        {application.documents && application.documents.length ? (
          application.documents.map((doc) => (
            <li
              key={doc.id}
              className="grid items-center grid-cols-6 gap-4 px-4 py-2 mb-4 bg-white rounded text-slate-800 dark:bg-slate-700 dark:text-slate-100"
            >
              {checkFiletype(doc.url) == "image" ? (
                <img
                  src={doc.url}
                  className="col-span-2 rounded mx-auto max-w-[200px] h-fit object-contain"
                />
              ) : checkFiletype(doc.url) == "pdf" ? (
                <iframe
                  src={doc.url}
                  className="col-span-2 mx-auto rounded max-w-[200px] h-fit"
                ></iframe>
              ) : (
                <p className="flex justify-center col-span-2">
                  <a
                    className="text-blue-500 underline"
                    href={doc.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View File
                  </a>
                </p>
              )}
              <p>{doc.user?.name}</p>
              <p>{doc.is_public ? "Yes" : "No"}</p>
              <p>
                {doc.created_at ? format(doc.created_at, "MMMdd , yyyy") : "-"}
              </p>
              <Button size="icon" variant="ghost">
                <EllipsisVertical />
              </Button>
            </li>
          ))
        ) : (
          <p className="my-6 text-center text-destructive">
            No Documents Uploaded.
          </p>
        )}
      </ul>
    </div>
  );
};

export default Documents;
