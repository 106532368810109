import { CvForm } from "@/types";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { ChevronLeft, ChevronRight, Plus, Trash } from "lucide-react";
import { Textarea } from "../ui/textarea";
import { useState } from "react";
import { CalendarDatePicker } from "../ui/calendar-date-picker";
import { format } from "date-fns";

type Props = {
  setTab: React.Dispatch<React.SetStateAction<string>>;
  cv: CvForm;
  setCv: React.Dispatch<React.SetStateAction<CvForm>>;
  errors: {
    [key: string]: string;
  };
};

const FormExperiences = ({ setTab, cv, setCv, errors }: Props) => {
  const [start, setStart] = useState({ from: new Date() });
  const [end, setEnd] = useState({ from: new Date() });
  const setArrayData = (index: number, key: string, value: string) => {
    const expArray = cv.work_experiences.map((exp, i) => {
      if (i == index) {
        exp[key] = value;
      }
      return exp;
    });
    setCv({ ...cv, work_experiences: expArray });
  };
  const addMoreExp = () => {
    const obj = {
      company_name: "",
      position: "",
      start_date: "",
      end_date: "",
      why_quit: "",
    };
    setCv({ ...cv, work_experiences: [...cv.work_experiences, obj] });
  };

  const removeExp = (index: number) => {
    const expArray = cv.work_experiences.filter((_, i) => i !== index);
    setCv({ ...cv, work_experiences: expArray });
  };

  return (
    <div>
      <h1 className="text-xl">Work Experience</h1>
      <div className="grid grid-cols-1 gap-4 mt-4 md:grid-cols-2">
        {errors["work_experiences.0.company_name"] ? (
          <p className="text-destructive">
            • {errors["work_experiences.0.company_name"]}
          </p>
        ) : null}
        {errors["work_experiences.0.position"] ? (
          <p className="text-destructive">
            • {errors["work_experiences.0.position"]}
          </p>
        ) : null}
        {errors["work_experiences.0.start_date"] ? (
          <p className="text-destructive">
            • {errors["work_experiences.0.start_date"]}
          </p>
        ) : null}
        {errors["work_experiences.0.end_date"] ? (
          <p className="text-destructive">
            • {errors["work_experiences.0.end_date"]}
          </p>
        ) : null}
      </div>
      {cv.work_experiences?.map((exp, i) => (
        <div
          key={i}
          className="grid grid-cols-1 gap-4 p-2 mt-4 border rounded-lg dark:border-slate-500 md:grid-cols-2"
        >
          <div className="flex items-center justify-between md:col-span-2">
            <h2 className="text-lg">• Experience {i + 1}</h2>
            <Button
              onClick={() => removeExp(i)}
              size="icon"
              variant="secondary"
            >
              <Trash />
            </Button>
          </div>
          <div>
            <Label>Company Name</Label>
            <Input
              value={cv.work_experiences[i]?.company_name}
              onChange={(e) => setArrayData(i, "company_name", e.target.value)}
              className="mt-1"
              placeholder=". . ."
            />
          </div>
          <div>
            <Label>Position</Label>
            <Input
              value={cv.work_experiences[i]?.position}
              onChange={(e) => setArrayData(i, "position", e.target.value)}
              className="mt-1"
              placeholder=". . ."
            />
          </div>
          <div>
            <Label>Start Date</Label>
            {/* <input
              type="date"
              value={cv.work_experiences[i]?.start_date}
              onChange={(e) => setArrayData(i, "start_date", e.target.value)}
              className="w-full p-2.5 mt-1 rounded-md outline-none bg-slate-100 dark:bg-slate-300 dark:text-slate-600 border-o ring-0"
            /> */}
            <CalendarDatePicker
              date={start}
              onDateSelect={({ from }) => {
                setStart({ from });
                setArrayData(i, "start_date", format(from, "yyyy-MM-dd"));
              }}
              variant="outline"
              numberOfMonths={1}
              className="flex justify-start w-full border-0 hover:bg-slate-100 dark:hover:bg-slate-300 hover:text-slate-700 bg-slate-100 dark:bg-slate-300 text-slate-800"
            />
          </div>
          <div>
            <Label>End Date</Label>
            {/* <input
              type="date"
              value={cv.work_experiences[i]?.end_date}
              onChange={(e) => setArrayData(i, "end_date", e.target.value)}
              className="w-full p-2.5 mt-1 rounded-md outline-none bg-slate-100 dark:bg-slate-300 dark:text-slate-600 border-o ring-0"
            /> */}
            <CalendarDatePicker
              date={end}
              onDateSelect={({ from }) => {
                setEnd({ from });
                setArrayData(i, "end_date", format(from, "yyyy-MM-dd"));
              }}
              variant="outline"
              numberOfMonths={1}
              className="flex justify-start w-full border-0 hover:bg-slate-100 dark:hover:bg-slate-300 hover:text-slate-700 bg-slate-100 dark:bg-slate-300 text-slate-800"
            />
          </div>
          <div className="md:col-span-2">
            <Label>Why did you quit?</Label>
            <Textarea
              value={cv.work_experiences[i]?.why_quit}
              onChange={(e) => setArrayData(i, "why_quit", e.target.value)}
              className="mt-1"
              placeholder=". . ."
            />
          </div>
        </div>
      ))}
      <div className="flex justify-center mt-4">
        <Button
          onClick={addMoreExp}
          className="flex items-center gap-1"
          variant="secondary"
        >
          <Plus />
          <span>Add More Experience</span>
        </Button>
      </div>
      <div className="flex justify-between mt-8 md:col-span-2">
        <Button
          onClick={() => setTab("basics")}
          className="flex items-center gap-1 bg-transparent rounded-full"
          variant="outline"
        >
          <ChevronLeft />
          <span>Previous</span>
        </Button>
        <Button
          onClick={() => setTab("edu")}
          className="flex items-center gap-1 bg-transparent rounded-full"
          variant="outline"
        >
          <span>Next</span>
          <ChevronRight />
        </Button>
      </div>
    </div>
  );
};

export default FormExperiences;
