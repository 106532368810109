import { Button } from "@/components/ui/button";
import { Job } from "@/types";
import { Link } from "react-router-dom";

type Props = {
  job: Job;
};

const AppliedList = ({ job }: Props) => {
  return (
    <div>
      <div className="flex p-3 mb-3">
        <p className="text-xl">
          Job Applications - ({job.applications?.length})
        </p>
      </div>
      <div className="grid grid-cols-5 px-3 py-2 text-white rounded-lg bg-foreground hover:bg-foreground dark:text-slate-800">
        <div>ID</div>
        <div>Name</div>
        <div>Job Title</div>
        <div>Applied Date</div>
        <div>Actions</div>
      </div>
      {job.applications.length ? (
        job.applications.map((app) => (
          <div
            key={app.user.id}
            className="grid items-center grid-cols-5 px-3 py-2 my-2 rounded-lg dark:border border-slate-700 bg-white/50 dark:bg-background"
          >
            <div>{app.user.id}</div>
            <div>{app.user.name}</div>
            <div>{job.title}</div>
            <div>{app.created_at?.slice(0, 10)}</div>
            <div>
              <Button
                asChild
                className="border bg-background dark:bg-foreground border-background text-slate-800"
              >
                <Link to={`/job-applications/${app.id}`}>View Details</Link>
              </Button>
            </div>
          </div>
        ))
      ) : (
        <p className="py-8 text-center">No Data.</p>
      )}
    </div>
  );
};

export default AppliedList;
